import React, { useEffect } from 'react';
import { ProductIconCard } from './ProductIconCard';
import { ProductVideoCard } from './ProductVideoCard';
import SearchSVG from '../../images/svg/SearchSVG';
import RocketSVG from '../../images/svg/RocketSVG';
import PeopleSVG from '../../images/svg/PeopleSVG';
import DataSVG from '../../images/svg/DataSVG';
import { Motion } from './Motion';
import { useTranslation } from 'react-i18next';
import DataUpdatesImage from '../../images/data-updates.jpg';
import AccelerateProductivityImage from '../../images/accelerate-productivity.jpg';
import FindCustomersImage from '../../images/find-customers.jpg';
import TalkToTheRightPeopleImage from '../../images/talk-to-the-right-people.jpg';

type ProductItem = {
	title:
		| 'Accelerate Productivity'
		| 'Talk to the Right People'
		| 'Data Updates'
		| 'Find Customers'
		| 'Vind Klanten'
		| 'Versnel Productiviteit'
		| 'Praat met de Juiste Mensen'
	type: string;
};

export const ProductsPageOverview = () => {
	const { t, i18n } = useTranslation();
	const products = t('products') as any;
	const mobileProducts = t('mobileProducts') as any;

	const [width, setWidth] = React.useState(window.innerWidth);
	//function to get the current screen width
	useEffect(() => {
		const handleResize = () => setWidth(window.innerWidth);
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});

	const getArray = () => {
		if (width < 768) {
			// return en.mobileProducts
			return mobileProducts;
		} else {
			// return en.products
			return products;
		}
	};

	const getIcon = (input: ProductItem['title']) => {
		switch (input) {
			case 'Find Customers':
				return <SearchSVG className="w-10 h-10" />;
			case 'Accelerate Productivity':
				return <RocketSVG className="w-10 h-10" />;
			case 'Talk to the Right People':
				return <PeopleSVG className="w-10 h-10" />;
			case 'Data Updates':
				return <DataSVG className="w-10 h-10" />;
			default:
				return <SearchSVG className="w-10 h-10" />;
		}
	};

	const getImageSrc = (input: ProductItem['title']) => {
		switch (input) {
			case 'Find Customers':
				return FindCustomersImage;
			case 'Vind Klanten':
				return FindCustomersImage;
			case 'Accelerate Productivity':
				return AccelerateProductivityImage;
			case 'Versnel Productiviteit':
				return AccelerateProductivityImage;
			case 'Talk to the Right People':
				return TalkToTheRightPeopleImage;
			case 'Praat met de Juiste Mensen':
				return TalkToTheRightPeopleImage;
			case 'Data Updates':
				return DataUpdatesImage;
			default:
				return FindCustomersImage;
		}
	};

	const getDirection = (num: any) => {
		return num % 2 === 0 ? 'left' : 'right';
	};

	return (
		<div className="max-w-5xl m-auto overflow-hidden">
			<div className="grid grid-cols-1 md:grid-cols-2 m-auto gap-6 overflow-hidden">
				{getArray()
					.filter((item: ProductItem) => !!item?.title && item?.type === 'icon')
					.map((item: ProductItem, index: number) => {
						if (index % 2 === 0) {
							return (
								<React.Fragment key={index}>
									<Motion
										duration={0.5}
										delay={index / 25}
										direction={getDirection(index)}
									>
										<ProductIconCard item={item} icon={getIcon(item?.title)} />
									</Motion>
									<Motion
										duration={0.5}
										delay={index / 25}
										direction={getDirection(index)}
										extraClass="hidden md:block"
									>
										<img
											key={index + 'img'}
											src={getImageSrc(item?.title)}
											alt={item?.title}
										/>
									</Motion>
								</React.Fragment>
							);
						} else {
							return (
								<React.Fragment key={index}>
									<Motion
										duration={0.5}
										delay={index / 25}
										direction={getDirection(index)}
										extraClass="hidden md:block"
									>
										<img
											key={index + 'img'}
											src={getImageSrc(item?.title)}
											alt={item?.title}
										/>
									</Motion>
									<Motion
										duration={0.5}
										delay={index / 25}
										direction={getDirection(index)}
									>
										<ProductIconCard item={item} icon={getIcon(item?.title)} />
									</Motion>
								</React.Fragment>
							);
						}
					})}
			</div>
		</div>
	);
};
