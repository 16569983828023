import React from "react";
import { WordUnderline } from "./WordUnderline";

type PageHeaderProps = {
  text: string
  subtext?: string
}

export const PageHeader = ({text, subtext}: PageHeaderProps) => {

  return(
    <div className="grid place-content-center py-20">
      <div className="max-w-2xl m-auto pt-16 md:pt-0">
          <WordUnderline text={text}/> 
      </div> 
      <div className="pt-4 max-w-md text-center text-lg font-semibold px-5">
        {subtext}
      </div>
    </div> 

  )
}