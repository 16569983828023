import React from "react";

type WordUnderlineProps = {
    text: string;
    small?: boolean;
}

export const WordUnderline = ({text, small = false}: WordUnderlineProps) => {
    
    let splitText = text.split(" ")
    
    const singleWord = (text: any) => { 
        return(
            <div className="relative px-1">
                <div className="relative z-10">
                    {text}
                </div>
                <div className={`${small ? "h-2 bottom-3" : "h-3 bottom-3" } bg-jig-blue absolute  left-0 w-full`} />
            </div>
        )
    }

    return(
        <div className={`flex flex-wrap ${small ? "text-h2" : "text-h1"} justify-center`}>
            {splitText.map((word, index) => (
                <div key={word + index}> 
                    {singleWord(word)}
                </div>
            ))}
        </div>
    )
}