import React from "react";
import { WordUnderline } from "./WordUnderline";
import { Motion } from "./Motion";

import DataIcon from "./../../images/DataIcon.png";
import TargetIcon from "./../../images/TargetIcon.png";
import DocumentIcon from "./../../images/DocumentIcon.png";
import RefreshIcon from "./../../images/RefreshIcon.png";
import UploadIcon from "./../../images/UploadIcon.png";

import { useTranslation } from "react-i18next";

export const GrowingCompaniesLoveJiggr = () => { 
  const { t, i18n } = useTranslation();

  return(
    <div className="m-auto font-semibold pb-20 bg-white px-5 overflow-hidden">
      <div className="py-16 max-w-sm m-auto">
          <WordUnderline text={t('growingCompaniesLoveJiggr.title')} small={true}/>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2  gap-5 m-auto max-w-3xl  ">
        <Motion duration={0.5} delay={0.1} direction="left">
          <div className="flex gap-x-4 pb-4 md:pb-0">
            <div className="w-[75px]">
                <img src={DataIcon} alt="Data Icon" />
            </div>
            <div className="w-[200px] flex items-center text-body">
                {t('growingCompaniesLoveJiggr.dataForOrganizations')}
            </div>
          </div>
        </Motion>
        <Motion duration={0.5} delay={0.2} direction="right" extraClass="md:ml-auto">
          <div className="flex gap-x-4 pb-4 md:pb-0">
            <div className="w-[75px]">
                <img src={RefreshIcon} alt="Refresh Icon" />
            </div>
            <div className="w-[200px] flex items-center text-body ">
                {t('growingCompaniesLoveJiggr.reliableData')}
            </div>
          </div>
        </Motion> 
        <Motion duration={0.5} delay={0.3} direction="left">
          <div className="flex gap-x-4 pb-4 md:pb-0">
            <div className="w-[75px]">
                <img src={UploadIcon} alt="Data Icon" />
            </div>
            <div className="w-[200px] flex items-center text-body ">
                {t('growingCompaniesLoveJiggr.directIntegration')}
            </div>
          </div>
        </Motion>
        <Motion duration={0.5} delay={0.4} direction="right" extraClass="md:ml-auto">
          <div className="flex gap-x-4 pb-4 md:pb-0">
            <div className="w-[75px]">
                <img src={TargetIcon} alt="Refresh Icon" />
            </div>
            <div className="w-[200px] flex items-center text-body ">
                {t('growingCompaniesLoveJiggr.salesTriggers')}
            </div>
          </div>
        </Motion> 
        <Motion duration={0.5} delay={0.5} direction="left">
          <div className="flex gap-x-4 pb-4 md:pb-0">
            <div className="w-[75px]">
                <img src={DocumentIcon} alt="Refresh Icon" />
            </div>
            <div className="w-[240px] flex items-center text-body">
                {t('growingCompaniesLoveJiggr.accessToInfo')}
            </div> 
          </div>
        </Motion> 
    </div>
  </div>
  )

}