import React from "react";
import { WordUnderline } from "../default/WordUnderline";

import en from './../../locales/en.json'
import nl from './../../locales/nl.json'

export const SearchFaq = () => {
    const searchItems = navigator.language.includes('nl') ? nl.searchPage : en.searchPage;

    const item = ({header, body}: any) => {
        return(
            <div>
                <div className="font-black text-jig-purple text-xl">
                    {header}
                </div>
                <div className="pt-2">
                    {body}
                </div>
            </div>
        )
    }

    return(
        <div className="bg-white">
            <div className="max-w-5xl m-auto py-28">
                <div className="pb-10 w-fit px-5 md:px-0">
                    <WordUnderline text="Search" small/>
                </div>
                <div className="flex flex-col md:flex-row gap-x-4 px-5 md:px-0">
                    <div className="md:w-2/5 md:max-w-1/3">
                        {item({header: searchItems[0].header, body: searchItems[0].body})}
                    </div>
                    <div className="md:w-3/5 flex flex-col gap-y-6 pt-4 md:pt-0">
                        {item({header: searchItems[1].header, body: searchItems[1].body})}
                        {item({header: searchItems[2].header, body: searchItems[2].body})}
                        {item({header: searchItems[3].header, body: searchItems[3].body})}

                    </div>
                </div>
            </div>
        </div>
    )
}