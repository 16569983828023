import React from "react"; 
import { WordUnderline } from "../components/default/WordUnderline"; 
import { FooterComponent } from "../components/default/FooterComponent"; 
import { JiggrHelps } from "../components/default/JiggrHelps";
import { JiggrInterested } from "../components/default/JiggrInterested";
import { PageHeader } from "../components/default/PageHeader";
import { ProductsPageOverview } from "../components/default/ProductsPageOverview";
import { ContactUs } from "../components/default/ContactUs";

import PortoIcon from  "../images/PortoIcon.png";
import AmsterdamIcon from "../images/AmsterdamIcon.png";
import EmailIcon from "../images/EmailIcon.png";
import { Motion } from "../components/default/Motion";

import { useTranslation } from "react-i18next";

export const Contact = ({toggleOpen}: any) => { 
  const { t, i18n } = useTranslation();
  return(
      <div className="">

        <PageHeader text={t('contactPage.pageHeader.text')}/> 

        <ContactUs showText toggleOpen={toggleOpen}/>

        <div className="max-w-3xl pb-20 w-full m-auto flex flex-row text-center justify-between">
          <Motion duration={0.3} delay={0.2} direction={"top"}>
            <img 
              src={AmsterdamIcon}
              className="w-fit h-fit m-auto"
            />
            <div className="font-bold text-lg text-jig-purple">
              {t('contactPage.officeDetails.amsterdamOffice.title')}
            </div>
            <div className="font-bold">
              SPACES
            </div>
            <div>
              Barbara Strozzilaan 201 <br/>
              1083 HN Amsterdam
            </div>
          </Motion>
          <Motion duration={0.3} delay={0.3}>
            <img 
              src={PortoIcon}
              className="w-fit h-fit m-auto"
            />
            <div className="font-bold text-lg text-jig-purple">
              {t('contactPage.officeDetails.portoOffice.title')}
            </div>
            <div className="font-bold">
              UPTEC
            </div>
            <div> 
              R. Alfredo Allen nº 455-461 <br/> 4200-135 Porto
            </div>
          </Motion>
          <Motion duration={0.3} delay={0.4} extraClass="md:px-0 px-4">
            <img 
              src={EmailIcon}
              className="w-fit h-fit m-auto"
            />
            <div className="font-bold text-lg text-jig-purple">
              {t('contactPage.officeDetails.contactInfo.title')}
            </div>
            <div className="font-bold">
              SALES
            </div>
            <div> 
              hello@jiggr.co <br/> 085 - 06 06 944
            </div>
          </Motion>
        </div>

        <JiggrInterested toggleOpen={toggleOpen}/> 

        <FooterComponent />  

      </div>
  )
}