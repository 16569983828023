import * as React from "react"

function BlueCheck(props: any) {
  return (
    <svg
      width={11}
      height={11}
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.5 0a5.5 5.5 0 100 11 5.5 5.5 0 000-11zm2.798 4.321c-.064.072-.135.138-.203.207L5.369 7.253c-.264.264-.505.264-.769 0-.472-.473-.948-.945-1.418-1.42-.254-.257-.158-.666.178-.769.185-.056.344-.005.48.132.353.355.708.71 1.063 1.063.025.025.05.049.086.081l1.366-1.367L7.63 3.698a.444.444 0 01.462-.127c.172.05.279.168.32.344a.437.437 0 01-.114.406z"
        fill="#B3FFF6"
      />
    </svg>
  )
}

export default BlueCheck
