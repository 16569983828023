import React from "react"; 
import { WordUnderline } from "../components/default/WordUnderline"; 
import { FooterComponent } from "../components/default/FooterComponent"; 
import { JiggrHelps } from "../components/default/JiggrHelps";
import { JiggrInterested } from "../components/default/JiggrInterested";
import { PageHeader } from "../components/default/PageHeader";
import { ProductsPageOverview } from "../components/default/ProductsPageOverview";
import { ContactUs } from "../components/default/ContactUs";

import PortoIcon from  "../images/PortoIcon.png";
import AmsterdamIcon from "../images/AmsterdamIcon.png";
import EmailIcon from "../images/EmailIcon.png";
import { Motion } from "../components/default/Motion";
import { SearchFaq } from "../components/faq/SearchFaq";
import { TargetFaq } from "../components/faq/TargetFaq";
import { PipelineFaq } from "../components/faq/PipelineFaq";
import { ClientsFaq } from "../components/faq/ClientsFaq";

import { useTranslation } from "react-i18next";

export const Help = ({toggleOpen}: any) => { 
  const { t, i18n } = useTranslation();
  return(
      <div className="">

        <PageHeader text={t('helpPage.header')}/> 

        <SearchFaq />

        <TargetFaq />

        <PipelineFaq />

        <ClientsFaq />

        <JiggrInterested toggleOpen={toggleOpen}/> 

        <FooterComponent />  

      </div>
  )
}