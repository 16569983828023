import React from "react";
import { Router, useNavigate } from "react-router-dom";

type PurpleButtonProps = {
    text: string;
    link: string;
    useClick?: boolean;
    click?: any;
}

export const PurpleButton = ({ text, link, useClick = false, click }: PurpleButtonProps) => {
    const navigate = useNavigate();
    return(
        <div className="purple-button" onClick={()=>useClick ? click() : navigate(link)}>
            {text}
        </div>
    )
}