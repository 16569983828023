import React from "react";
import { WordUnderline } from "../default/WordUnderline";

import en from './../../locales/en.json'
import nl from './../../locales/nl.json'

export const PipelineFaq = () => { 
    const items = navigator.language.includes('nl') ? nl.pipelinePage : en.pipelinePage;

    const item = ({header, body}: any) => {
        return(
            <div>
                <div className="font-black text-jig-purple text-xl">
                    {header}
                </div>
                <div className="pt-4">
                    {body}
                </div>
            </div>
        )
    }

    return(
        <div className="bg-transparent">
            <div className="max-w-5xl m-auto py-28 px-5">
                <div className="pb-10 w-fit ">
                    <WordUnderline text="Pipeline" small/>
                </div>
                <div className="flex flex-col md:flex-row gap-x-4">
                    <div className="flex-1 max-w-1/3 gap-y-6 flex flex-col">
                        {item({header: items[0].header, body: items[0].body})}
                        {item({header: items[1].header, body: items[1].body})}
                        {item({header: items[2].header, body: items[2].body})}
                        {item({header: items[3].header, body: items[3].body})}
                    </div>
                    <div className="flex-1 flex pt-4 md:pt-0 flex-col gap-y-6">
                        {item({header: items[4].header, body: items[4].body})}
                        {item({header: items[5].header, body: items[5].body})}
                        {item({header: items[6].header, body: items[6].body})}
                        {item({header: items[7].header, body: items[7].body})}
                    </div>
                </div>
            </div>
        </div>
    )
}