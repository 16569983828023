import React from "react";  
import { FooterComponent } from "../components/default/FooterComponent";  
import { PageHeader } from "../components/default/PageHeader"; 
import { JobItem } from "../components/jobs/JobItem";

import JobsImage from './../images/JobsImage.png'
 
import { useTranslation } from "react-i18next";

export const Jobs = ({toggleOpen}: any) => {  
  const { t, i18n } = useTranslation();
  let jobs = [
    {
      title: "Account Manager Sales (Junior)",
      sub: "Amsterdam",
      type: "Full Time",
      body: `
        <body>
          <h1 class="font-bold">Heb jij het lef om bij een snelgroeiende, internationale start-up de euro’s binnen te halen?</h1>
          <p>Heb je een enthousiaste vlotte babbel en schroom je niet om nieuwe klanten telefonisch te overtuigen van onze toegevoegde waarde? Ga jij samen met je salescollega’s van deze start-up een scale-up maken? En wil je werken bij een bedrijf waar geen één dag hetzelfde is? Neem dan contact met ons op, want wij zoeken een (junior) Account Manager!</p>
          <h2 class="font-bold pt-4">Wat doe je in deze job?</h2>
          <p>Voor onze vestiging in Amsterdam zijn we op zoek naar collega’s die het salesvak willen leren. Na een uitgebreid inwerktraject en door middel van coaching on the job leren we jou de kneepjes van het vak. Hierna ga je pro-actief en zelfstandig interessante prospects (B2B) benaderen. Je geeft demonstraties, sluit deals en plant afspraken in. Indien nodig zorg jij ervoor dat een collega van de buitendienst optimaal voorbereid naar de prospect kan gaan. Sluiten jullie samen de deal, dan word ook jij hier natuurlijk voor beloond. Uiteraard houd je je eigen administratie netjes bij.</p>
          <h2 class="font-bold pt-4">Wat krijg je ervoor terug?</h2>
          <ul>
              <li>Een marktconform salaris</li>
              <li>Een aantrekkelijke bonusregeling</li>
              <li>Uitstekende opleiding en trainingen</li>
              <li>Doorgroeimogelijkheden</li>
              <li>Een toffe werkplek (B2 Building) met leuke collega’s</li>
              <li>Een open en informele bedrijfscultuur</li>
              <li>Goede bereikbaarheid (dichtbij metro)</li>
              <li>Reiskostenvergoeding</li>
              <li>Gezellig vrijdagmiddag borrels</li>
          </ul>
          <h2 class="font-bold pt-4">De gewenste skills:</h2>
          <p>Je spreekt uitstekend Nederlands en Engels. Je hebt een vriendelijke, vlotte babbel en een gezonde dosis lef. Je weet anderen vaak met gemak te overtuigen. Je kunt goed omgaan met veranderingen en werkt graag door totdat het werk af is. Je vindt het leuk om uitgedaagd te worden en krijgt een kick als resultaten (boven verwachting) gehaald worden. Hoewel je graag zelfstandig en pro-actief werkt, ben je ook een echte teamplayer. Samen met je collega’s werk je tenslotte aan hetzelfde doel: de beste worden in de branche!</p>
          <h2 class="font-bold pt-4">Over ons</h2>
          <p>Wij zijn een hardgroeiende, internationale start-up en leveren bedrijfsinformatie, financiële- en kredietinformatie aan het bedrijfsleven. Daarnaast geven we als enige bedrijf in de wereld ook inzicht in de duurzaamheidsscores van andere bedrijven. Zo krijgen klanten die gebruikmaken van ons platform beter inzicht in waar zij, hun leveranciers en hun (potentiële) klanten staan op het gebied van maatschappelijk verantwoord ondernemen en hun financiën. Een uniek concept waar we al diverse awards mee in de wacht hebben gesleept!</p>
      </body>`
    }
  ]

  return(
      <div className="min-h-screen flex flex-col">

        <PageHeader text={t('jobsPage.header')} subtext={t('jobsPage.subtitle')}/> 

        <div className="max-w-3xl text-center w-fit m-auto pb-20 px-5">
          <div className="text-lg font-black text-jig-purple pb-2">
            {t('jobsPage.description1')}
          </div>
          <div className="">
            {t('jobsPage.description2')}
          </div>
          <div className="font-bold pt-4">
            {t('jobsPage.description3')}
          </div>
        </div>

        <div className="max-w-4xl m-auto b-20 gap-y-2 flex-1 px-3 bg-jobs-background">
          {jobs.map((job: any, index: number) => (
            <div key={index}>
              <JobItem item={job} />
            </div>
          ))}
          <div>
            <img 
              className=""
              src={JobsImage}
            />
          </div>
        </div>

        <FooterComponent />  

      </div>
  )
}