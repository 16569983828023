import { FooterComponent } from "../components/default/FooterComponent";  
import { JiggrInterested } from "../components/default/JiggrInterested";
import { PageHeader } from "../components/default/PageHeader"; 

import StoryImage from './../images/StoryImage.png'
import TechnologyImage from './../images/TechnologyImage.png'
import MeetTeamSmallSVG from "../images/svg/MeetTeamSmallSVG";
import { Motion } from "../components/default/Motion";

import { useTranslation } from "react-i18next";

export const About = ({toggleOpen}: any) => { 
  const { t, i18n } = useTranslation();

  return(
      <div className="">
        
        <PageHeader text={t('aboutPage.pageHeader.text')} subtext={t('aboutPage.pageHeader.subtext')}/> 
        
        <div className="bg-white py-20 overflow-hidden px-5">
          <div className="flex flex-row w-fit m-auto">
            <div className="md:pr-10 max-w-lg px-5 md:px-0">
              <Motion duration={0.3} delay={0.0} direction={"left"}>
                <div className="text-lg font-black text-jig-purple">
                  {t('aboutPage.smartLeadGeneration.title')}
                </div>
                <div className="">
                  {t('aboutPage.smartLeadGeneration.description')}
                </div>
                <div className="font-bold pt-4">
                  {t('aboutPage.smartLeadGeneration.belief')}
                </div>
              </Motion>
              <Motion duration={0.3} delay={0.1} direction={"left"}>
                <div className="text-lg font-black text-jig-purple pt-6">
                  {t('aboutPage.realTimeClientInsights.title')}
                </div>
                <div className="">
                  {t('aboutPage.realTimeClientInsights.description')}
                </div> 
              </Motion>
              <div className="md:hidden">
                <Motion duration={0.3} delay={0.0} direction={"right"}>
                  <img
                    className="object-cover scale-75"
                    src={StoryImage}
                  />
                </Motion>
              </div>
              <Motion duration={0.3} delay={0.2} direction={"left"}>
                <div className="text-lg font-black text-jig-purple pt-6">
                  {t('aboutPage.windowOfOpportunity.title')}
                </div>
                <div className="">
                  {t('aboutPage.windowOfOpportunity.description')}
                </div> 
              </Motion>
              <Motion duration={0.3} delay={0.3} direction={"left"}>
                <div className="text-lg font-black text-jig-purple pt-6">
                  {t('aboutPage.closeDealsQuicker.title')}
                </div>
                <div className="">
                  {t('aboutPage.closeDealsQuicker.description')}
                </div> 
              </Motion>
              <div className="md:hidden">
                <Motion duration={0.3} delay={0.2} direction={"right"}>
                  <img
                    className="object-cover scale-75"
                    src={TechnologyImage}
                  />
                </Motion>
              </div>
            </div>
            <div className="w-1/3 pt-4 hidden md:block">
              <Motion duration={0.3} delay={0.0} direction={"right"}>
                <img
                  className="object-cover scale-75"
                  src={StoryImage}
                />
              </Motion>
              <Motion duration={0.3} delay={0.2} direction={"right"}>
                <img
                  className="object-cover scale-75"
                  src={TechnologyImage}
                />
              </Motion>
            </div>
          </div>
        </div>

        <div className="bg-[#E1CEFF] py-20 relative overflow-hidden">
          <div className="max-w-4xl m-auto px-5">
            <div className="font-black text-xl pb-5">
              {t('aboutPage.theTeam.title')}
            </div>
            <div className="flex flex-row gap-x-6">
              <div className="pr-6">
                <div className="font-bold">
                  {t('aboutPage.theTeam.description1')}
                </div>
                <div className="pt-6">
                  {t('aboutPage.theTeam.description2')}
                </div>
              </div>
              <div className="relative z-10">
                {t('aboutPage.theTeam.description3')}
              </div>
            </div>
          </div>
          <div className="absolute -right-44 md:right-0 bottom-0 ">
            <MeetTeamSmallSVG />
          </div>
        </div>

        <JiggrInterested toggleOpen={toggleOpen}/> 

        <FooterComponent />  

      </div>
  )
}