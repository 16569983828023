import React from "react";
import { BlueButton } from "./BlueButton"; 
import JeanImg from "./../../images/headshots/jean-1.jpg";

import { useTranslation } from "react-i18next";

export const JiggrInterested = ({toggleOpen}:any) => { 
  const { t, i18n } = useTranslation();

  return(
    <div className="bg-jig-purple py-20 px-5">
      <div className="flex flex-col lg:flex-row max-w-5xl m-auto items-center">
        <div className="w-40 h-40 rounded-full flex items-center background-gradient-dark">
          <div className="w-36 h-36 rounded-full overflow-hidden m-auto">
            <img 
              className="w-full h-full bg-inherit"
              src={JeanImg}
              />
          </div>
        </div>
        <div className="lg:ml-8 text-center lg:text-left ">
          <div className="font-bold text-jig-blue text-xl">
            {t('jiggrInterested.title')}
          </div>
          <div className="text-white text-lg max-w-lg px-5 lg:px-0">
            {t('jiggrInterested.description')}
          </div>
        </div>
        <div className="m-auto lg:ml-auto pt-4 lg:pt-0">
          <BlueButton text={t('jiggrInterested.bookDemoButton')} link="/" click={toggleOpen} useClick/>
        </div>
      </div>
    </div>
  )
}