import { PencilIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";  
import PurpleCheck from "../../images/svg/PurpleCheck";
import { PurpleButton } from "./PurpleButton"; 
import { useTranslation } from "react-i18next";

export const ProductCardPremium = ({toggleOpen, openDemo}: any) => { 
  const { t, i18n } = useTranslation();

  return(  
    <div className="group [perspective:750px]"> 
      <div className={`relative h-[420px] w-64 aspect-video rounded-3xl shadow-lg shadow-black/40 transition-all duration-[500ms] [transform-style:preserve-3d]`}>
        
        {/* Card Front */}
        <div className="absolute inset-0 overflow-hidden rounded-3xl bg-white flex flex-col p-4">
          <div className="pb-2">
            <div className="font-bold text-jig-purple text-2xl">
              {t('productCardPremium.title')}
            </div>
            <div className="font-black text-4xl" dangerouslySetInnerHTML={{__html:t('productCardPremium.price')}}> 
            </div>
          </div> 
          <div className="flex-1 font-bold">
            {t('productCardPremium.description')}
          </div>
          {/* <div className="font-semibold text-jig-purple text-center underline decoration-white cursor-pointer hover:decoration-jig-purple animate-200" onClick={toggleOpen}>
            More details
          </div> */}
          {/* <div className="m-auto w-fit pt-3">
            <PurpleButton text={messages.bookDemoButton} link="/" click={toggleOpen} useClick/>
          </div>  */}
        </div> 
      </div>
    </div> 
  )
}