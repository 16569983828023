import * as React from "react"

function MeetTeamSVG(props: any) {
  return (
    <svg
      width={535}
      height={355}
      viewBox="0 0 535 355"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1769_415)">
        <path
          d="M81.217 368.663s-87.581-186.562 85.034-186.562c91.212 0 53.63-51.191 115.638-110.426 62.007-59.235 278.86-106.56 325.012 7.825 46.153 114.386-67.88 201.243 42.324 186.346C781.678 247.943 834 368.663 834 368.663H81.217z"
          fill="#EDE9FF"
          fillOpacity={0.36}
        />
        <path d="M286 329H180v26h106v-26z" fill="#573EC1" />
        <path d="M254 329H147v26h107v-26z" fill="#7656D8" />
        <path d="M327 355.643h79.14V245H327v110.643z" fill="#59D1BA" />
        <path d="M305 355h79V244h-79v111z" fill="#8FEFDC" />
        <path d="M382.861 241.994l24.4 16.612v-16.612h-24.4z" fill="#00C9AC" />
        <path
          d="M453.472 85.79c-1.221-.184-4.909 3.224-4.909 3.224-7.872.911-19.382 3.002-23.525 3.798-2.937.565-10.836-2.127-18.551-5.22-9.168-3.68-18.093-7.925-18.093-7.925-5.093-.258-10.877 9.034-.55 14.94 1.576.903 7.5 2.444 14.319 3.846 9.407 1.94 20.524 3.623 24.306 3.032 4.395-.686 16.836-5.239 24.25-8.726h.021l.08-.039c.446-.207 2.256-1.014 3.466-.943 1.387.083 4.474-2.455 4.569-3.133.094-.677-4.158-2.668-5.383-2.854z"
          fill="url(#paint0_linear_1769_415)"
        />
        <path
          d="M443.861 202.851l-4.335.888s-19.835-22.292-22.896-31.671c-1.638-5.029-12.125-7.179-23.067-10.01-9.525-2.464-19.408-5.431-24.128-11.223-10.144-12.441 6.373-27.728 6.373-27.728s17.56 10.439 32.708 21.224c10.59 7.537 20 15.239 21.416 19.67 3.46 10.75 10.765 31.227 13.929 38.85z"
          fill="url(#paint1_linear_1769_415)"
        />
        <path
          d="M438.059 210.202h2.44c.816-.127 1.419-.364 2.256-.473 1.272-.166 1.857.355 1.976.473l.02.026h10.351c.263-.372 0-1.236-.234-1.869a1.475 1.475 0 00-.97-.887c-1.097-.317-3.253-.592-5.246-2.396-1.115-1.009-5.385-3.707-5.385-3.707h-.024c-.095 0-.541.139-2.484 1.775-1.003.846-2.715-1.183-2.715-1.183a2.445 2.445 0 00-.562.763 2.563 2.563 0 00-.222 1.589c.154.934-.343 2.662-.742 3.845-.242.71-.443 1.216-.443 1.216s-.113.576 1.984.828z"
          fill="#2E3B61"
        />
        <path
          d="M394.942 135.061s-1.204 3.804-3.168 9.524c-2.481 7.232-6.178 17.529-10.185 27.08a232.914 232.914 0 01-2.682 6.15c-3.333 7.351-6.746 13.648-9.759 16.86-5.4 5.757-18.528 42.758-18.528 42.758l-3.27-.905s-4.56-31.597 11.595-49.576c.181-.181.295-.417.326-.671.215-2.662.984-14.58 2.661-26.657.411-2.958.887-5.934 1.402-8.78.032-.183.068-.364.1-.544 2.458-13.16 4.809-19.014 7.846-21.96l22.334 6.36 1.328.361z"
          fill="url(#paint2_linear_1769_415)"
        />
        <path
          d="M346.995 241.97h2.442c.82-.13 1.423-.367 2.26-.476 1.271-.166 1.857.355 1.972.476l.024.024h10.351c.26-.373 0-1.234-.234-1.87a1.483 1.483 0 00-.97-.887c-1.097-.316-3.253-.592-5.246-2.396-1.115-1.009-5.389-3.706-5.389-3.706h-.02c-.095 0-.541.139-2.484 1.775-1.006.846-2.715-1.198-2.715-1.198a2.414 2.414 0 00-.562.763 2.568 2.568 0 00-.222 1.588c.154.935-.346 2.662-.742 3.845-.243.71-.447 1.216-.447 1.216s-.115.583 1.982.846z"
          fill="#2E3B61"
        />
        <path
          d="M351.786 92.09s-3.738 9.143-8.047 18.044c-3.623 7.483-7.651 14.79-10.203 16.351-3.599 2.201-13.518 8.407-19.95 13.036 0 0-5.027-.038-5.802.923-.775.961-2.579 5.28-2.052 5.718.526.438 4.515.234 5.485-.76.846-.867 2.73-1.479 3.197-1.636l.086-.027h.021c7.816-2.405 20.08-7.418 23.791-9.868 3.197-2.111 10.283-10.84 15.934-18.611 4.099-5.62 7.444-10.758 7.985-12.486 3.676-11.329-6.855-14.304-10.445-10.684z"
          fill="url(#paint3_linear_1769_415)"
        />
        <path
          d="M371.321 56.234a18.588 18.588 0 00-1.336-1.73c.56.029 1.12-.056 1.647-.249 1.313-.541 1.91-4.706 1.712-5.943-.198-1.236-2.017-4.806-4.264-5.164-2.248-.358-4.569 2.141-4.569 2.141.417-1.801 4.217-2.842 4.217-2.842-7.831 1.446-7.432 6.667-7.376 7.152-7.168-2.555-11.376 10.945-11.376 10.945l18.566 10.944s.05-2.982 1.821-2.79c2.768.3 5.066-6.486.958-12.464z"
          fill="#000035"
        />
        <path
          d="M372.504 84.562c-4.258 2.009-9.913-.887-9.883-.914.367-.366.237-1.46-.098-2.89-.334-1.478-1.123-3.531-2.584-5.966a25.66 25.66 0 00-.976-1.582l.488-.184 8.28-3.316s1.34 2.402 2.739 4.594c1.183 1.828 2.434 3.47 2.981 3.41.032-.032 5.512 3.743-.947 6.848z"
          fill="url(#paint4_linear_1769_415)"
        />
        <path
          d="M359.46 78.152s10.776-4.247 8.026-10.92c-2.75-6.674-3.209-11.711-10.203-9.283-6.994 2.429-7.278 5.526-6.902 7.987.375 2.46 6.204 13.148 9.079 12.216z"
          fill="url(#paint5_linear_1769_415)"
        />
        <path
          d="M363.479 65.711c4.974-.704 6.426-2.715 6.426-2.715a13.776 13.776 0 01-7.275-5.804s-5.592-1.884-9.735 1.27a2.794 2.794 0 00-.524 1.168c-.949-.296-2.366-.231-3.203 2.455-1.239 4.032 3.168 8.439 3.168 8.439 1.067-.296-.136-3.899-.296-5.819-.124-1.41.636-3.218 1.047-4.075.529.686 5.778 7.282 12.184 7.545a27.055 27.055 0 01-6.474-3.46c1.532.742 3.162 1.212 4.682.996z"
          fill="#000035"
        />
        <path
          d="M393.028 98.308a38.277 38.277 0 00-.296-1 25.195 25.195 0 002.718-8.15c.731-4.93-.514-7.986-.514-7.986s-7.476-3.966-10.777-4.469a.105.105 0 00-.041 0h-.13c-3.333-.459-10.546 1.014-10.546 1.014s1.393 3.704-2.534 6.174c-3.928 2.47-8.118-.633-8.118-.633l-.399.278s-10.327 6.803-11.256 8.374c-.929 1.57-4.897 9.27-4.897 9.27s1.257 6.472 15.209 7.013c1.148 2.784 2.026 5.499 2.313 7.762 1.106 8.788-.181 15.722 1.112 20.851 1.292 5.129 24.572 2.203 28.786 1.218 4.214-.985 3.253-5.49 3.59-9.864.411-5.511-2.859-24.992-4.22-29.852z"
          fill="#00C9AC"
        />
        <path
          d="M395.453 89.159a25.234 25.234 0 01-2.738 8.185c-.364-1.612-1.855-7.517-4.259-10.197 0 0 4.046.367 6.997 2.012z"
          fill="#039882"
        />
        <path
          d="M300.672 146.756s4.335-5.256 5.27-5.877c.934-.621 5.027-1.71 5.264-1.479.236.231.245.535-.264.985-.508.45-2.445 1.58-2.167 1.707s6.121-2.784 6.964-1.805c.843.979-.479 5.029-2.73 6.958-2.25 1.928-8.975 2.762-9.398 3.031-3.584 2.29-2.939-3.52-2.939-3.52z"
          fill="url(#paint6_linear_1769_415)"
        />
        <path
          d="M224.314 134.617s6.231 7.395 14.166 4.301c0 0 1.706-4.141 1.73-7.173v-1.083c.041-3.425.156-5.351.156-5.351-.36-.151-12.124-4.984-12.124-4.582.215 9.271-3.928 13.888-3.928 13.888z"
          fill="url(#paint7_linear_1769_415)"
        />
        <path
          d="M242.845 124.956s-13.234 3.112-15.044-5.161c-1.81-8.274-4.732-13.444 3.634-15.781 8.366-2.337 10.712.458 11.989 3.073 1.278 2.615 2.818 16.846-.579 17.869z"
          fill="url(#paint8_linear_1769_415)"
        />
        <path
          d="M228.253 122.048c-.18.116-3.028.991-8.422-9.761-.846-1.686-1.423-5.324.55-8.56 1.973-3.236 8.972-5.76 14.786-6.863 5.815-1.103 7.207 2.169 8.577 5.458 1.437 3.452 5.589 6.294 5.589 6.294-5.027 2.802-11.051-1.206-13.142-.547-2.091.66-2.833 3.958-2.99 5.014-.157 1.056-.396 1.934-3.028.991-2.632-.944-1.183 3.73-.769 4.623 0 .006.358 2.384-1.151 3.351z"
          fill="url(#paint9_linear_1769_415)"
        />
        <path
          d="M194.161 329.079h-8.014c-1.68 0-3.549-1.704-4.013-4.828-.464-3.123-3.921-4.824-4.669-7.395-.749-2.57 3.081-6.152 3.081-6.152l.642-.834.665-.87 6.347 1.514s.156 1.533.254 3.485c.084 1.487.093 2.978.027 4.466-.187 3.408.943 7.076 3.927 7.739 2.984.662 1.753 2.875 1.753 2.875zM249.948 329.156h-21.242a1.96 1.96 0 01-.45-.134c-1.419-.733-.976-6.324-.804-8.051.032-.32.056-.509.056-.509s7.47-6.152 8.496-1.44c.033.148.071.295.11.434 1.298 4.411 7.097 6.41 10.442 6.969 2.558.429 3.22 1.964 3.392 2.731z"
          fill="#000035"
        />
        <path
          d="M245.666 205.848s-1.133 14.156-2.467 31.171c-1.812 23.116-3.992 51.513-4.208 56.89-.372 9.341-1.866 24.282-1.866 24.282s-3.549 3.55-10.646 2.053c0 0-6.326-21.327-8.133-43.896-.026-.37-.056-.743-.083-1.115-1.682-22.972-4.294-71.347-4.294-71.347l31.697 1.962z"
          fill="url(#paint10_linear_1769_415)"
        />
        <path
          d="M240.488 207.528s-20.447 51.27-33.894 75.363c-13.447 24.092-17.646 30.819-17.646 30.819s-7.689-3.082-8.402-5.185c0 0 5.14-46.514 14.145-57.852 5.086-6.407 11.063-25.634 11.344-32.36.281-6.727 1.822-20.032 1.822-20.032l32.631 9.247z"
          fill="url(#paint11_linear_1769_415)"
        />
        <path
          d="M193.671 198.988s-.53 6.404-.917 7.395c-.387.991-2.957 4.032-3.271 3.987-.313-.044-.479-.295-.328-.902.151-.606 1.044-2.538.76-2.487-.284.05-3.253 5.445-4.418 5.144-1.165-.302-2.331-4.142-1.63-6.854.701-2.713 5.459-6.969 5.643-7.395 1.541-3.724 4.161 1.112 4.161 1.112z"
          fill="url(#paint12_linear_1769_415)"
        />
        <path
          d="M191.476 200.612c2.266.728 2.626.565 2.626.565s11.016-20.061 12.125-21.889c1.109-1.828 23.39-22.48 23.39-22.48l-5.726-21.351s-25.329 32.813-28.065 37.519c-4.521 7.782-7.171 25.849-7.171 25.849a6.63 6.63 0 002.821 1.787z"
          fill="#00C9AC"
        />
        <path
          d="M249.983 165.167c-.079 4.384-.334 9.312-.677 14.319l-.062.888c-.222 3.1-.458 6.211-.733 9.237a362.03 362.03 0 01-.346 3.597l-.021.16c0 .441-.062.92-.112 1.358-1.115 11.352-2.286 20.321-2.505 20.46a1.287 1.287 0 01-.87.222c-2.978-.376-11.858.296-19.127-1.281-6.137-1.296-11.419-3.748-17.017-7.058-.742-.437-2.437-1.603-2.298-2.472.134-.956.385-2.337.701-4.074 1.103-6.037 3.008-16.425 3.659-28.582.124-2.264.306-4.497.547-6.7a101.773 101.773 0 012.957-16c2.493-8.977 6.157-15.854 10.753-18.543.591-.337 3.844 1.718 6.801 3.623a13.102 13.102 0 008.432 2l2.469 2.73c2.99 3.479 6.175 8.108 7.044 14.689a32.9 32.9 0 01.169 1.55c.247 3.286.326 6.583.236 9.877z"
          fill="url(#paint13_linear_1769_415)"
        />
        <path
          d="M249.984 165.167c-.08 4.383-.335 9.311-.678 14.319l-.062.888c-17.001-10.649-19.621-30.124-19.621-30.124 8.363.367 15.008 2.289 20.13 5.028.245 3.29.322 6.591.231 9.889z"
          fill="#00C9AC"
        />
        <path
          d="M303.25 147.283c-.887-2.367-2.238-2.219-2.238-2.219s-25.279 6.304-27.548 6.573c-2.268.269-27.207-9.747-27.207-9.747s-9.821-4.407-14.423-.381c-3.998 3.496-4.178 7.93-3.253 11.651a11.135 11.135 0 009.402 8.283c10.179 1.298 30.492 3.738 34.748 3.144 9.463-1.319 30.945-13.713 30.945-13.713a7.945 7.945 0 00-.426-3.591z"
          fill="url(#paint14_linear_1769_415)"
        />
        <path
          d="M508.744 209.865c-.357.177-1.608.295-3.327.384h-16.623c.057-.494.444-2.257 3.162-2.698 3.069-.496 8.39-2.283 9.576-6.211.041-.127.073-.258.103-.385.94-4.2 7.793 1.284 7.793 1.284s.02.169.05.453c.159 1.544.565 6.522-.734 7.173z"
          fill="#000035"
        />
        <path
          d="M492.769 97.574s1.041 12.989 2.265 28.601c1.662 21.206 3.661 47.256 3.859 52.193.341 8.578 1.713 22.277 1.713 22.277s3.253 3.253 9.759 1.884c0 0 5.802-19.567 7.461-40.273.027-.34.05-.68.077-1.023 1.547-21.076 3.945-65.46 3.945-65.46l-29.079 1.801z"
          fill="#00C9AC"
        />
        <path
          d="M492.769 97.574s1.041 12.989 2.265 28.601l22.798 36.087c.027-.34.05-.68.077-1.023 1.541-21.082 3.939-65.466 3.939-65.466l-29.079 1.801z"
          fill="#59D1BA"
        />
        <path
          d="M497.521 99.115s18.761 47.032 31.096 69.143c12.335 22.111 16.189 28.275 16.189 28.275s7.068-2.828 7.709-4.756c0 0-4.717-42.675-12.976-53.078-4.67-5.878-10.153-23.519-10.41-29.686-.257-6.168-1.668-18.378-1.668-18.378l-29.94 8.48z"
          fill="#00C9AC"
        />
        <path
          d="M439.659 73.606s-5.087-1.165-5.944-1.106c-.858.059-3.845 1.313-3.892 1.556-.048.242.103.44.621.473.517.032 2.221-.183 2.111.024-.109.207-5.027 1.183-5.068 2.159-.042.976 2.635 2.825 4.906 2.958s6.739-2.517 7.121-2.55c3.247-.296.145-3.514.145-3.514z"
          fill="url(#paint15_linear_1769_415)"
        />
        <path
          d="M437.482 74.896c-.73-2.322-.559-2.695-.559-2.695s20.627-11.155 22.514-12.285c1.887-1.13 23.206-23.82 23.206-23.82l21.831 6.01S470.687 67.85 465.846 70.63c-8 4.582-26.53 7.173-26.53 7.173a6.76 6.76 0 01-1.834-2.907z"
          fill="url(#paint16_linear_1769_415)"
        />
        <path
          d="M489.259 25.078s-7.654 13.47-15.136 15.227c0 0 .311-4.523 2.133-7.62a97.638 97.638 0 01-1.97-2.41 45.562 45.562 0 01-2.777-3.893l2.537-6.827 1.724-4.632a22.349 22.349 0 002.692 3.878c4.903 5.629 10.797 6.277 10.797 6.277z"
          fill="url(#paint17_linear_1769_415)"
        />
        <path
          d="M469.504 27.367s11.436-4.46 8.549-11.536c-2.886-7.075-3.353-12.423-10.776-9.877-7.423 2.547-7.733 5.825-7.346 8.422.387 2.597 6.512 13.955 9.573 12.991z"
          fill="url(#paint18_linear_1769_415)"
        />
        <path
          d="M528.57 94.19c-4.616 6.632-39.107 15.497-38.699 10.907a50.007 50.007 0 000-9.058 61.52 61.52 0 00-.367-3.13c-.887-6.034-2.957-13.792-7.6-22.776a88.838 88.838 0 00-1.088-2.038 70.052 70.052 0 01-4.782-10.749c-2.39-6.907-3.354-13.515-2.147-17.836l.017-.06c7.21-3.336 10.005-16.268 10.005-16.268s24.599 4.416 38.377 37.214c-.003.003 10.297 28.018 6.284 33.794z"
          fill="url(#paint19_linear_1769_415)"
        />
        <path
          d="M451.092 88.653s-6.802.358-7.861.74c-1.058.381-4.368 3.023-4.329 3.342.038.32.295.518.946.376.651-.142 2.724-1.027 2.662-.728-.062.299-5.879 3.254-5.596 4.523.284 1.269 4.318 2.606 7.216 1.946 2.898-.66 7.562-5.567 8.029-5.747 3.978-1.515-1.067-4.452-1.067-4.452z"
          fill="url(#paint20_linear_1769_415)"
        />
        <path
          d="M449.808 91.236c-1.363-2.204-.381-3.207-.381-3.207s21.023-16.464 22.67-18.126c1.648-1.663 9.28-28.145 9.28-28.145s2.627-10.717 8.778-11.9c5.347-1.027 9.04 1.618 11.459 4.732a11.406 11.406 0 01.772 12.823c-5.367 9.046-16.194 27-19.355 30.07-7.039 6.828-30.582 16.367-30.582 16.367a8.138 8.138 0 01-2.641-2.614z"
          fill="url(#paint21_linear_1769_415)"
        />
        <path
          d="M479.683 2.671c.08-.157.313-.325.887-.5a2.387 2.387 0 00-1.15-.041c-.323-.784-1.103-2.118-2.567-2.13a1.802 1.802 0 011.15 1.059c-2.008-1.272-5.725-1.642-15.008 1.452-12.235 4.076-6.122 11.179-4.268 11.75 1.855.57 5.267-3.086 8.337-3.586 3.07-.5 4.732 3.778 5.915 4.141 1.183.364.505-.618 1.73-1.59 1.224-.974 1.931 1.315 1.904 2.928-.026 1.611 2.366 3.135 2.366 3.135l1.402-2.57c1.399-2.571 1.02-11.906-.32-13.587l-.378-.46z"
          fill="#000035"
        />
        <path d="M286 329v14l-32-14h32z" fill="#3A27AA" />
        <path
          d="M566.775 210.249H458.393v142.388h108.382V210.249z"
          fill="#573EC1"
        />
        <path d="M536 212H428v143h108V212z" fill="#7656D8" />
        <path
          d="M566.775 210.249v21.291l-33.417-21.291h33.417z"
          fill="#3A27AA"
        />
        <path
          d="M480.364 282.383s-12.154 8.928-14.536 8.395c-2.383-.532 8.892-9.638 8.892-9.638-4.24-2.01-17.415-.751-17.415-.751s-4.388 13.966-6.564 12.865c-2.175-1.101 4.524-12.169 4.524-12.169-4.721-.281-10.511 3.176-10.511 3.176-7.925 6.177.523 15.901.523 15.901s-1.627 4.797-6.632 9.184c0 0-7.149 5.218-7.645 14.539l1.296.115s2.056-8.681 5.795-12.787c.146-.149.357-.363.617-.622a23.756 23.756 0 001.323-1.285c1.859-1.763 4.431-4.011 5.669-4.229a2.438 2.438 0 01-.054-.614c.017.196.037.4.054.614.425 4.915 10.088 9.208 16.044 10.091 5.955.883 4.108-1.53 2.059-5.104-2.05-3.574-8.53-5.174-10.008-6.822-1.478-1.648 1.577-1.651 5.158-.036 3.581 1.614 4.072 5.917 9.818 8.635 5.747 2.718 14.391-6.692 14.391-6.692-4.579-.24-11.119-5.136-10.88-6.637.238-1.502 11.099 2.59 12.956 2.784 1.858.195 6.078-2.082 6.736-9.462.659-7.381-11.61-9.451-11.61-9.451z"
          fill="#00C9AC"
        />
        <path
          d="M416.184 288.097s13.41-7.6 7.152-17.896c0 0-5.005-6.324-10.368-7.949 0 0 2.86 15.355 0 15.718-2.86.363-1.968-17.346-1.968-17.346s-14.123-6.864-19.666-6.322c0 0 8.76 14.995 5.9 14.635-2.86-.361-12.695-15.54-12.695-15.54s-14.482-2.696-16.806 5.964c-2.324 8.661 1.431 13.007 3.576 13.549 2.144.542 15.913.361 15.554 2.17s-9.652 4.697-14.839 3.069c0 0 5.723 14.274 13.231 13.551 7.508-.723 9.834-5.42 14.482-5.78 4.648-.361 8.044.902 5.72 2.167-2.324 1.265-10.188.414-13.943 3.64-3.756 3.226-6.793 5.212.177 6.658 6.969 1.446 19.489.542 21.992-4.878l2.501-5.41z"
          fill="url(#paint22_linear_1769_415)"
        />
        <path
          d="M419.487 300.601c-3.759-6.936-3.582-12.985-3.582-12.985C411.609 274.1 374 262 374 262c47.817 19.213 39.4 30.953 39.4 30.953 1.288.745 3.22 4.306 4.561 7.038.189.466.486 1.108.943 1.981.182.396.328.725.427.944 2.462 6.119 1.157 16.686 1.157 16.686l1.494.398c3.305-10.649-2.495-19.399-2.495-19.399z"
          fill="#00C9AC"
        />
        <path
          d="M437.549 277.763s-17.567-9.895-9.369-23.327c0 0 6.56-8.246 13.586-10.368 0 0-3.746 20.028 0 20.499 3.747.47 2.578-22.619 2.578-22.619s18.505-8.954 25.768-8.246c0 0-11.48 19.556-7.73 19.085 3.749-.47 16.636-20.264 16.636-20.264s18.976-3.536 22.021 7.776c3.046 11.312-1.875 16.965-4.684 17.671-2.809.706-20.85.485-20.382 2.828.468 2.343 12.651 6.126 19.444 4.007 0 0-7.497 18.614-17.334 17.671-9.837-.944-12.885-7.068-18.976-7.541-6.09-.473-10.539 1.179-7.494 2.828 3.046 1.649 13.351.541 18.27 4.747 4.918 4.205 8.902 6.791-.241 8.685-9.143 1.894-25.532.706-28.811-6.362l-3.282-7.07z"
          fill="url(#paint23_linear_1769_415)"
        />
        <path
          d="M492 244s-49.116 15.85-54.736 33.566c0 0 .241 7.918-4.678 17.016 0 0-7.574 11.464-3.255 25.418l1.948-.523s-1.702-13.844 1.512-21.862c.132-.295.323-.72.561-1.236.585-1.144.98-1.989 1.23-2.596 1.751-3.58 4.276-8.246 5.959-9.223 0 0-11-15.384 51.459-40.56z"
          fill="#00C9AC"
        />
        <path
          d="M436.5 351c9.15 0 17.925-3.687 24.395-10.251C467.365 334.185 471 325.283 471 316h-69c0 9.283 3.635 18.185 10.105 24.749C418.575 347.313 427.35 351 436.5 351z"
          fill="url(#paint24_linear_1769_415)"
        />
        <path
          d="M445 350.733a34.43 34.43 0 01-14.534-1.295 34.643 34.643 0 01-12.723-7.206 35.003 35.003 0 01-8.665-11.843A35.282 35.282 0 01406 316h8.619c0 8.53 3.087 16.766 8.681 23.162a34.604 34.604 0 0021.7 11.571z"
          fill="url(#paint25_linear_1769_415)"
        />
        <path
          d="M467.74 350h-61.481c-.559 0-1.113.129-1.63.38a4.265 4.265 0 00-1.382 1.084 5.114 5.114 0 00-.923 1.622A5.768 5.768 0 00402 355h70c0-.657-.11-1.307-.324-1.914a5.114 5.114 0 00-.923-1.622 4.265 4.265 0 00-1.382-1.084 3.725 3.725 0 00-1.631-.38z"
          fill="#8FEFDC"
        />
        <path
          d="M471.47 316h-69.94a.55.55 0 00-.375.146.488.488 0 00-.155.354c0 .133.056.26.155.354a.55.55 0 00.375.146h69.94a.55.55 0 00.375-.146.488.488 0 00.155-.354.488.488 0 00-.155-.354.55.55 0 00-.375-.146z"
          fill="#59D1BA"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1769_415"
          x1={382.11}
          y1={90.635}
          x2={458.843}
          y2={90.635}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECB9B8" />
          <stop offset={1} stopColor="#E5908C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1769_415"
          x1={366.155}
          y1={163.413}
          x2={443.861}
          y2={163.413}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7656D8" />
          <stop offset={1} stopColor="#3A27AA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1769_415"
          x1={346.767}
          y1={182.876}
          x2={394.942}
          y2={182.876}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7656D8" />
          <stop offset={1} stopColor="#3A27AA" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1769_415"
          x1={305.637}
          y1={118.508}
          x2={363.009}
          y2={118.508}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECB9B8" />
          <stop offset={1} stopColor="#E5908C" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1769_415"
          x1={358.969}
          y1={77.4899}
          x2={375.654}
          y2={77.4899}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECB9B8" />
          <stop offset={1} stopColor="#E5908C" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1769_415"
          x1={350.277}
          y1={67.7698}
          x2={367.935}
          y2={67.7698}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECB9B8" />
          <stop offset={1} stopColor="#E5908C" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1769_415"
          x1={300.624}
          y1={145.079}
          x2={315.993}
          y2={145.079}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECB9B8" />
          <stop offset={1} stopColor="#E5908C" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1769_415"
          x1={224.314}
          y1={130.189}
          x2={240.381}
          y2={130.189}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECB9B8" />
          <stop offset={1} stopColor="#E5908C" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1769_415"
          x1={225.796}
          y1={114.334}
          x2={244.906}
          y2={114.334}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECB9B8" />
          <stop offset={1} stopColor="#E5908C" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1769_415"
          x1={219.05}
          y1={109.382}
          x2={249.333}
          y2={109.382}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#003" />
          <stop offset={1} stopColor="#3A27AA" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1769_415"
          x1={213.972}
          y1={262.25}
          x2={245.666}
          y2={262.25}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7656D8" />
          <stop offset={1} stopColor="#3A27AA" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1769_415"
          x1={180.546}
          y1={255.997}
          x2={240.488}
          y2={255.997}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7656D8" />
          <stop offset={1} stopColor="#3A27AA" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_1769_415"
          x1={183.654}
          y1={204.295}
          x2={193.671}
          y2={204.295}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECB9B8" />
          <stop offset={1} stopColor="#E5908C" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_1769_415"
          x1={206.207}
          y1={173.041}
          x2={250.01}
          y2={173.041}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CAFFF5" />
          <stop offset={1} stopColor="#8FEFDC" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_1769_415"
          x1={228.108}
          y1={152.249}
          x2={303.721}
          y2={152.249}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CAFFF5" />
          <stop offset={1} stopColor="#8FEFDC" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_1769_415"
          x1={427.487}
          y1={76.0789}
          x2={440.995}
          y2={76.0789}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECB9B8" />
          <stop offset={1} stopColor="#E5908C" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_1769_415"
          x1={436.906}
          y1={56.9437}
          x2={504.474}
          y2={56.9437}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CAFFF5" />
          <stop offset={1} stopColor="#8FEFDC" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_1769_415"
          x1={471.509}
          y1={27.6007}
          x2={489.259}
          y2={27.6007}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECB9B8" />
          <stop offset={1} stopColor="#E5908C" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_1769_415"
          x1={459.813}
          y1={16.3635}
          x2={478.509}
          y2={16.3635}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECB9B8" />
          <stop offset={1} stopColor="#E5908C" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_1769_415"
          x1={473.363}
          y1={64.7736}
          x2={529.499}
          y2={64.7736}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CAFFF5" />
          <stop offset={1} stopColor="#8FEFDC" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_1769_415"
          x1={436.906}
          y1={93.8413}
          x2={453.688}
          y2={93.8413}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECB9B8" />
          <stop offset={1} stopColor="#E5908C" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_1769_415"
          x1={449.084}
          y1={61.7357}
          x2={503.998}
          y2={61.7357}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CAFFF5" />
          <stop offset={1} stopColor="#8FEFDC" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_1769_415"
          x1={367}
          y1={276.009}
          x2={425}
          y2={276.009}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CAFFF5" />
          <stop offset={1} stopColor="#8FEFDC" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_1769_415"
          x1={426}
          y1={261.999}
          x2={501.992}
          y2={261.999}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CAFFF5" />
          <stop offset={1} stopColor="#8FEFDC" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_1769_415"
          x1={402}
          y1={333.501}
          x2={471}
          y2={333.501}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CAFFF5" />
          <stop offset={1} stopColor="#8FEFDC" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_1769_415"
          x1={406}
          y1={333.501}
          x2={445}
          y2={333.501}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CAFFF5" />
          <stop offset={1} stopColor="#8FEFDC" />
        </linearGradient>
        <clipPath id="clip0_1769_415">
          <path fill="#fff" d="M0 0H663V355H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MeetTeamSVG
