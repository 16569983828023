import React from 'react';
import { PurpleButton } from './PurpleButton';
import { Motion } from './Motion';
import { ProductCardProfessional } from './ProductCardProfessional';
import { ProductCardBusiness } from './ProductCardBusiness';
import { ProductCardPremium } from './ProductCardPremium';

import { useTranslation } from 'react-i18next';

type ProductCardProps = {
	showMonthly?: boolean;
	toggleOpen?: () => void;
};

export const ProductCards = ({
	showMonthly = false,
	toggleOpen,
}: ProductCardProps) => {
	const [selected, setSelected] = React.useState(0);
	const { t, i18n } = useTranslation();

	return (
		<div className="m-auto pb-20">
			{!showMonthly ? (
				<div className="text-center font-black text-3xl text-jig-purple max-w-2xl m-auto pt-20 px-5">
					{t('productCards.focusMessage')}
				</div>
			) : (
				<div className="shadow-lg flex relative m-auto w-fit h-fit rounded-full bg-white font-bold">
					<div
						className={`cursor-pointer w-[180px] select-none flex justify-center py-2 rounded-full relative z-10 ${
							selected === 0 ? 'text-jig-purple bg-jig-blue' : 'text-gray-300'
						} animate-200`}
						onClick={() => setSelected(0)}
					>
						{t('productCards.billedMonthly')}
					</div>
					<div
						className={`cursor-pointer w-[180px] select-none flex justify-center py-2 rounded-full relative z-10 ${
							selected != 0 ? 'text-jig-purple bg-jig-blue' : 'text-gray-300'
						} animate-200`}
						onClick={() => setSelected(1)}
					>
						{t('productCards.billedYearly')}
					</div>
				</div>
			)}
			<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 w-fit m-auto gap-8 mt-16">
				<Motion duration={0.3} delay={0.0}>
					<div>
						<ProductCardProfessional
							selected={selected}
							openDemo={toggleOpen}
						/>
					</div>
				</Motion>
				<Motion duration={0.3} delay={0.1}>
					<div>
						<ProductCardBusiness selected={selected} openDemo={toggleOpen} />
					</div>
				</Motion>
				<Motion duration={0.3} delay={0.2}>
					<div>
						<ProductCardPremium toggleOpen={toggleOpen} />
					</div>
				</Motion>
			</div>
			<div>
				<Motion duration={0.3} delay={0.3}>
					<div className="m-auto max-w-4xl px-16 md:px-8 w-full pt-8">
						<div
							className="shadow-lg w-full text-center pt-3 pb-1.5 h-fit px-4  rounded-full bg-jig-blue text-jig-purple font-semibold cursor-pointer select-none animate-200
    hover:bg-jig-blue/80
    active:bg-jig-blue/60"
							onClick={toggleOpen}
						>
							{t('productCards.buttonText')}
						</div>
					</div>
				</Motion>
			</div>
		</div>
	);
};
