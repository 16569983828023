import React from 'react';
import { WordUnderline } from '../components/default/WordUnderline';
import { PurpleButton } from '../components/default/PurpleButton';

import { Testimonials } from '../components/default/Testimonials';
import { ProductCards } from '../components/default/ProductCards';
import { FooterComponent } from '../components/default/FooterComponent';
import { JiggrHelps } from '../components/default/JiggrHelps';
import { JiggrInterested } from '../components/default/JiggrInterested';
import { OurProducts } from '../components/default/OurProducts';
import JiggrSearchImage from './../images/JiggrSearchPage.png';
import { MeetTheTeam } from '../components/default/MeetTheTeam';
import { ApiConnections } from '../components/default/ApiConnections';
import { GrowingCompaniesLoveJiggr } from '../components/default/GrowingCompaniesLoveJiggr';

import { useTranslation } from 'react-i18next';

export const Home = ({ toggleOpen }: any) => {
	const { t, i18n } = useTranslation();
	let test = `<span class="font-black">Europe's largest company database, </span>JIGGR's smart sales platform <span class="font-black">helps companies </span>focus sales and marketing efforts on <span class="font-black">finding companies most likely to convert.</span>`;

	return (
		<div className="">
			<div className="min-h-screen grid place-content-center pt-6 lg:pt-16">
				<div className="max-w-2xl m-auto mt-32 md:mt-0">
					<WordUnderline text={t('homePage.header')} />
				</div>
				<div className="text-center text-h3 pt-8 px-5 md:px-0">
					{t('homePage.subtitle')}
				</div>
				<div className="w-fit m-auto pt-8 pb-8 md:pb-0">
					<PurpleButton
						text={t('homePage.bookDemoButtonText')}
						link="/"
						click={toggleOpen}
						useClick
					/>
				</div>

				{/* Video */}
				{/* <div className="rounded-xl h-[380px] bg-jig-purple/10 overflow-hidden md:mt-16 px-2 md:px-0">
            <iframe
              style={{
                overflow: 'hidden',
                width: '100%',
                height: '100%',
              }}
              className="w-full h-full rounded-xl overflow-hidden"
              src={`https://share.synthesia.io/embeds/videos/4a245a0a-7155-4f81-991d-d581222d8e4f?autoplay=1&mute=0`}
              title="How to ... Tag, Tips & Tricks"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
              scrolling="no"
            />
          </div> */}

				<a
					href="https://search.jiggr.co"
					target="_blank"
					rel="noreferrer"
					className="w-[90vw] md:w-[75vw] 2xl:w-[65vw] md:my-12  rounded-xl overflow-hidden shadow-xl mx-auto"
				>
					<img
						src={JiggrSearchImage}
						alt="Check out our Jiggr platform"
						width="100%"
						height="100%"
					/>
				</a>
			</div>

			{/* <div className="m-auto grid place-content-center text-5xl font-black bg-jig-purple aspect-video text-white">

      </div> */}

			<div className="min-h-[350px] bg-jig-blue flex items-center justify-text text-justify">
				<div
					className="text-center max-w-xl m-auto text-jig-purple text-h4-medium px-5"
					dangerouslySetInnerHTML={{ __html: t('homePage.jiggrDescription') }}
				></div>
			</div>

			<div>
				<GrowingCompaniesLoveJiggr />
			</div>

			<div>
				<JiggrHelps toggleOpen={toggleOpen} />
			</div>

			<div>
				<ApiConnections />
			</div>

			<div className="w-full bg-laptop-image bg-no-repeat bg-cover bg-center">
				<div className="max-w-lg m-auto py-40 px-5">
					<WordUnderline text={t('homePage.neverMissSalesOpportunity')} />
				</div>
			</div>

			<div>
				<ProductCards toggleOpen={toggleOpen} />
			</div>

			<div>
				<JiggrInterested toggleOpen={toggleOpen} />
			</div>

			<div>
				<OurProducts />
			</div>

			{/* <div className="flex flex-row w-full max-w-6xl m-auto hidden md:block" >
        <Testimonials />
      </div>   */}

			<div>
				<MeetTheTeam />
			</div>

			<div>
				<FooterComponent />
			</div>
		</div>
	);
};
