import React from "react";

function MeetTeamSmallSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="443"
      height="205"
      fill="none"
      viewBox="0 0 443 205"
    >
      <path
        fill="#EDE9FF"
        fillOpacity="0.33"
        d="M11.624 203.694s-47.898-99.19 50.766-99.19c52.135 0 30.654-29.264 66.097-63.127 35.442-33.864 159.391-60.918 185.772 4.473 26.38 65.391-38.8 115.045 24.191 106.529C414.158 142.144 443 203.694 443 203.694H11.624z"
      ></path>
      <path
        fill="#573EC1"
        d="M130.837 188.482H70.249v14.863h60.588v-14.863z"
      ></path>
      <path
        fill="#7656D8"
        d="M112.545 188.482h-61.16v14.863h61.16v-14.863z"
      ></path>
      <path
        fill="#59D1BA"
        d="M154.546 204.002h45.317v-65.678h-45.317v65.678z"
      ></path>
      <path
        fill="#8FEFDC"
        d="M141.406 203.345h45.318v-65.02h-45.318v65.02z"
      ></path>
      <path
        fill="#00C9AC"
        d="M186.07 138.325l13.793 11.165v-11.165H186.07z"
      ></path>
      <path
        fill="url(#paint0_linear_1782_13601)"
        d="M226.562 49.445c-.698-.105-2.806 1.843-2.806 1.843-4.499.521-11.078 1.717-13.446 2.172-1.679.323-6.194-1.216-10.604-2.985-5.24-2.103-10.341-4.53-10.341-4.53-2.911-.147-6.217 5.164-.315 8.541.901.516 4.287 1.397 8.185 2.199 5.377 1.109 11.731 2.07 13.893 1.733 2.512-.393 9.623-2.995 13.861-4.989h.012l.045-.022c.255-.118 1.29-.58 1.981-.54.793.048 2.558-1.403 2.612-1.79.054-.387-2.377-1.525-3.077-1.632z"
      ></path>
      <path
        fill="url(#paint1_linear_1782_13601)"
        d="M221.067 116.366l-2.478.508s-11.337-12.744-13.087-18.106c-.936-2.874-6.93-4.104-13.184-5.722-5.445-1.409-11.094-3.105-13.792-6.416-5.798-7.112 3.643-15.85 3.643-15.85s10.037 5.966 18.695 12.132c6.053 4.308 11.432 8.712 12.242 11.245 1.977 6.145 6.152 17.852 7.961 22.209z"
      ></path>
      <path
        fill="#2E3B61"
        d="M217.753 120.568h1.395c.467-.072.811-.208 1.29-.27.727-.095 1.061.203 1.129.27l.012.016h5.916c.15-.214 0-.707-.134-1.069a.843.843 0 00-.554-.507c-.627-.181-1.859-.339-2.999-1.37-.637-.577-3.078-2.119-3.078-2.119h-.013c-.054 0-.31.08-1.42 1.015-.573.483-1.552-.677-1.552-.677a1.37 1.37 0 00-.321.437 1.466 1.466 0 00-.127.908c.088.534-.196 1.521-.424 2.198-.139.406-.254.695-.254.695s-.064.33 1.134.473z"
      ></path>
      <path
        fill="url(#paint2_linear_1782_13601)"
        d="M193.105 77.612s-.688 2.175-1.811 5.445c-1.418 4.135-3.531 10.02-5.821 15.481-.507 1.2-1.015 2.379-1.533 3.516-1.905 4.202-3.856 7.802-5.579 9.638-3.086 3.291-10.59 24.443-10.59 24.443l-1.869-.517s-2.607-18.063 6.628-28.341a.66.66 0 00.186-.384c.123-1.522.563-8.335 1.521-15.239.235-1.691.507-3.392.801-5.019l.058-.311c1.404-7.523 2.748-10.87 4.484-12.554l12.766 3.636.759.206z"
      ></path>
      <path
        fill="#2E3B61"
        d="M165.701 138.729h1.396c.468-.074.813-.209 1.292-.272.727-.095 1.061.203 1.127.272l.014.014h5.916c.149-.213 0-.705-.134-1.069a.847.847 0 00-.554-.507c-.627-.181-1.859-.338-2.999-1.37-.637-.576-3.08-2.119-3.08-2.119h-.011c-.054 0-.31.08-1.42 1.015-.575.484-1.552-.685-1.552-.685a1.378 1.378 0 00-.321.436 1.47 1.47 0 00-.127.909c.088.534-.198 1.521-.424 2.198-.139.406-.255.695-.255.695s-.066.333 1.132.483z"
      ></path>
      <path
        fill="url(#paint3_linear_1782_13601)"
        d="M168.439 53.047s-2.137 5.227-4.6 10.315c-2.07 4.279-4.372 8.455-5.831 9.348-2.057 1.258-7.727 4.806-11.403 7.452 0 0-2.874-.022-3.317.528-.443.55-1.474 3.018-1.173 3.269.301.25 2.581.133 3.136-.435.483-.496 1.56-.846 1.827-.935l.049-.015h.012c4.467-1.375 11.477-4.241 13.599-5.641 1.827-1.208 5.877-6.198 9.107-10.64 2.343-3.213 4.255-6.15 4.564-7.138 2.101-6.476-3.918-8.178-5.97-6.108z"
      ></path>
      <path
        fill="#000035"
        d="M179.604 32.55c-.235-.345-.49-.676-.764-.99.32.017.641-.032.942-.142.75-.31 1.092-2.69.978-3.397-.113-.707-1.152-2.748-2.437-2.953-1.285-.204-2.612 1.224-2.612 1.224.239-1.03 2.411-1.625 2.411-1.625-4.476.827-4.248 3.812-4.216 4.09-4.097-1.462-6.503 6.256-6.503 6.256l10.612 6.257s.029-1.705 1.042-1.595c1.582.17 2.895-3.708.547-7.126z"
      ></path>
      <path
        fill="url(#paint4_linear_1782_13601)"
        d="M180.282 48.744c-2.435 1.148-5.666-.508-5.65-.523.21-.21.136-.835-.055-1.652-.191-.845-.643-2.019-1.478-3.41a14.956 14.956 0 00-.557-.905l.278-.105 4.733-1.896s.766 1.373 1.566 2.626c.676 1.045 1.391 1.984 1.703 1.95.019-.019 3.151 2.14-.54 3.915z"
      ></path>
      <path
        fill="url(#paint5_linear_1782_13601)"
        d="M172.825 45.08s6.159-2.429 4.587-6.244c-1.572-3.814-1.834-6.694-5.832-5.306-3.997 1.388-4.16 3.159-3.945 4.566.215 1.407 3.546 7.516 5.19 6.984z"
      ></path>
      <path
        fill="#000035"
        d="M175.124 37.967c2.843-.402 3.673-1.552 3.673-1.552a7.877 7.877 0 01-4.159-3.318s-3.196-1.077-5.564.726a1.59 1.59 0 00-.299.667c-.543-.169-1.353-.131-1.831 1.404-.708 2.305 1.81 4.824 1.81 4.824.611-.169-.077-2.228-.169-3.326-.071-.806.364-1.84.599-2.33.302.392 3.303 4.163 6.964 4.314a15.437 15.437 0 01-3.7-1.979c.875.425 1.807.694 2.676.57z"
      ></path>
      <path
        fill="#00C9AC"
        d="M192.01 56.602a22.523 22.523 0 00-.169-.572 14.412 14.412 0 001.554-4.659c.417-2.819-.294-4.565-.294-4.565s-4.274-2.268-6.16-2.555a.065.065 0 00-.024 0h-.074c-1.905-.262-6.028.58-6.028.58s.796 2.117-1.449 3.529c-2.244 1.412-4.64-.362-4.64-.362l-.228.159s-5.902 3.89-6.433 4.787c-.531.898-2.799 5.3-2.799 5.3s.718 3.7 8.693 4.009c.656 1.591 1.158 3.143 1.322 4.437.632 5.024-.103 8.988.635 11.92.739 2.932 14.046 1.26 16.454.697 2.409-.564 1.86-3.139 2.052-5.64.235-3.15-1.634-14.287-2.412-17.065z"
      ></path>
      <path
        fill="#039882"
        d="M193.399 51.371a14.434 14.434 0 01-1.565 4.68c-.208-.922-1.06-4.297-2.435-5.83 0 0 2.313.21 4 1.15z"
      ></path>
      <path
        fill="url(#paint6_linear_1782_13601)"
        d="M139.222 84.298s2.478-3.005 3.012-3.36c.534-.355 2.873-.977 3.009-.845.135.132.14.306-.151.563-.291.257-1.398.903-1.239.976.159.072 3.499-1.592 3.981-1.032.482.56-.274 2.875-1.56 3.977-1.287 1.103-5.13 1.58-5.372 1.734-2.049 1.308-1.68-2.013-1.68-2.013z"
      ></path>
      <path
        fill="url(#paint7_linear_1782_13601)"
        d="M95.578 77.358s3.561 4.228 8.096 2.46c0 0 .976-2.368.989-4.102v-.618c.024-1.959.09-3.06.09-3.06-.206-.086-6.93-2.849-6.93-2.619.123 5.3-2.245 7.94-2.245 7.94z"
      ></path>
      <path
        fill="url(#paint8_linear_1782_13601)"
        d="M106.169 71.836s-7.564 1.779-8.599-2.951c-1.034-4.73-2.705-7.686 2.077-9.022 4.782-1.335 6.123.263 6.853 1.757.73 1.495 1.611 9.63-.331 10.216z"
      ></path>
      <path
        fill="url(#paint9_linear_1782_13601)"
        d="M97.83 70.174c-.104.066-1.732.566-4.815-5.58-.483-.965-.813-3.044.315-4.894 1.127-1.85 5.128-3.293 8.451-3.924 3.324-.63 4.12 1.24 4.902 3.12.822 1.974 3.195 3.599 3.195 3.599-2.873 1.601-6.317-.69-7.512-.313-1.195.377-1.619 2.262-1.709 2.866-.089.604-.226 1.106-1.73.567-1.505-.54-.677 2.132-.44 2.643 0 .003.204 1.363-.658 1.916z"
      ></path>
      <path
        fill="#000035"
        d="M78.345 188.527h-4.581c-.96 0-2.029-.974-2.294-2.76-.265-1.786-2.241-2.758-2.669-4.227-.428-1.47 1.761-3.518 1.761-3.518l.367-.476.38-.498 3.628.866s.09.876.145 1.992c.049.85.054 1.703.016 2.554-.107 1.948.539 4.044 2.244 4.423 1.706.379 1.003 1.644 1.003 1.644zM110.231 188.571H98.089a1.147 1.147 0 01-.257-.076c-.811-.42-.557-3.616-.46-4.603l.033-.291s4.27-3.517 4.856-.823c.019.084.041.169.063.248.742 2.521 4.056 3.664 5.968 3.984 1.462.245 1.841 1.123 1.939 1.561z"
      ></path>
      <path
        fill="url(#paint10_linear_1782_13601)"
        d="M107.784 118.079s-.648 8.093-1.41 17.82c-1.036 13.215-2.282 29.448-2.405 32.523-.213 5.34-1.067 13.881-1.067 13.881s-2.029 2.029-6.085 1.173c0 0-3.616-12.192-4.649-25.094l-.047-.637c-.962-13.133-2.454-40.787-2.454-40.787l18.117 1.121z"
      ></path>
      <path
        fill="url(#paint11_linear_1782_13601)"
        d="M104.823 119.04s-11.687 29.31-19.373 43.083c-7.686 13.773-10.086 17.618-10.086 17.618s-4.395-1.762-4.803-2.964c0 0 2.938-26.591 8.085-33.072 2.908-3.663 6.324-14.655 6.485-18.5.16-3.845 1.04-11.451 1.04-11.451l18.652 5.286z"
      ></path>
      <path
        fill="url(#paint12_linear_1782_13601)"
        d="M78.064 114.158s-.302 3.661-.523 4.227c-.222.567-1.69 2.305-1.87 2.28-.18-.026-.274-.169-.188-.516.087-.347.597-1.451.435-1.422-.163.029-1.86 3.113-2.526 2.941-.666-.173-1.332-2.368-.931-3.919.4-1.55 3.12-3.983 3.225-4.227.88-2.129 2.378.636 2.378.636z"
      ></path>
      <path
        fill="#00C9AC"
        d="M76.807 115.086c1.295.416 1.501.323 1.501.323s6.297-11.468 6.93-12.513c.635-1.045 13.37-12.852 13.37-12.852L95.335 77.84S80.858 96.597 79.294 99.287c-2.585 4.449-4.1 14.778-4.1 14.778.442.472.998.824 1.613 1.021z"
      ></path>
      <path
        fill="url(#paint13_linear_1782_13601)"
        d="M110.25 94.823c-.046 2.506-.191 5.323-.387 8.186l-.036.507a280.864 280.864 0 01-.419 5.281c-.063.695-.129 1.38-.198 2.057l-.012.091c0 .252-.035.526-.064.776-.637 6.49-1.307 11.617-1.432 11.697a.736.736 0 01-.497.126c-1.702-.214-6.778.17-10.933-.732-3.507-.74-6.526-2.142-9.726-4.034-.424-.251-1.393-.917-1.313-1.414.076-.546.22-1.336.4-2.329.63-3.451 1.72-9.39 2.091-16.34a71.22 71.22 0 01.313-3.83c.319-3.089.884-6.147 1.69-9.146 1.425-5.132 3.52-9.064 6.146-10.601.338-.193 2.198.982 3.888 2.071a7.483 7.483 0 004.819 1.144l1.412 1.56c1.709 1.989 3.529 4.635 4.026 8.398.037.277.069.573.096.886.142 1.878.187 3.763.136 5.646z"
      ></path>
      <path
        fill="#00C9AC"
        d="M110.249 94.823c-.046 2.506-.191 5.323-.387 8.186l-.036.508c-9.718-6.088-11.215-17.221-11.215-17.221 4.78.21 8.578 1.308 11.506 2.874.14 1.881.184 3.768.132 5.653z"
      ></path>
      <path
        fill="url(#paint14_linear_1782_13601)"
        d="M140.698 84.6c-.507-1.354-1.279-1.27-1.279-1.27s-14.45 3.604-15.746 3.758c-1.297.154-15.551-5.572-15.551-5.572s-5.614-2.52-8.244-.218c-2.285 2-2.388 4.534-1.86 6.661a6.362 6.362 0 005.374 4.735c5.818.742 17.429 2.137 19.862 1.797 5.409-.754 17.688-7.839 17.688-7.839a4.55 4.55 0 00-.244-2.053z"
      ></path>
      <path
        fill="#000035"
        d="M283.173 111.094s2.007 1.86 1.616 3.182c-.39 1.322-2.197 2.198-2.449 3.822-.252 1.623-1.224 2.497-2.105 2.497h-4.202s-.647-1.138.918-1.481c1.565-.343 2.159-2.241 2.06-4.004-.098-1.764.148-4.114.148-4.114l3.328-.785.686.883zM258.153 120.376c-.204.101-.919.169-1.902.219h-9.501c.032-.282.254-1.29 1.807-1.542 1.755-.284 4.795-1.305 5.473-3.551.024-.072.043-.147.059-.22.538-2.401 4.455.734 4.455.734l.028.259c.092.883.323 3.729-.419 4.101z"
      ></path>
      <path
        fill="#00C9AC"
        d="M249.021 56.182s.596 7.425 1.295 16.35c.95 12.123 2.093 27.016 2.206 29.838.195 4.904.979 12.735.979 12.735s1.859 1.86 5.578 1.077c0 0 3.316-11.186 4.265-23.023l.044-.585c.884-12.048 2.255-37.421 2.255-37.421l-16.622 1.03z"
      ></path>
      <path
        fill="#59D1BA"
        d="M249.021 56.182s.596 7.425 1.295 16.35l13.031 20.63.044-.585c.881-12.051 2.252-37.424 2.252-37.424l-16.622 1.03z"
      ></path>
      <path
        fill="#00C9AC"
        d="M251.739 57.063s10.723 26.887 17.774 39.527c7.05 12.64 9.253 16.164 9.253 16.164s4.04-1.616 4.406-2.719c0 0-2.696-24.395-7.417-30.343-2.669-3.36-5.803-13.445-5.95-16.97-.147-3.526-.953-10.507-.953-10.507l-17.113 4.848z"
      ></path>
      <path
        fill="url(#paint15_linear_1782_13601)"
        d="M218.666 42.48s-2.908-.666-3.398-.632c-.49.034-2.197.75-2.224.89-.027.138.059.251.355.27.295.018 1.269-.105 1.207.013-.063.119-2.874.677-2.898 1.235-.023.558 1.506 1.615 2.805 1.69 1.298.077 3.852-1.438 4.07-1.457 1.856-.169.083-2.009.083-2.009z"
      ></path>
      <path
        fill="url(#paint16_linear_1782_13601)"
        d="M217.42 43.218c-.417-1.328-.319-1.54-.319-1.54s11.79-6.378 12.868-7.024c1.079-.646 13.265-13.617 13.265-13.617l12.478 3.436s-19.313 14.717-22.08 16.306c-4.572 2.62-15.164 4.1-15.164 4.1a3.877 3.877 0 01-1.048-1.661z"
      ></path>
      <path
        fill="url(#paint17_linear_1782_13601)"
        d="M247.015 14.738s-4.375 7.7-8.651 8.705c0 0 .177-2.585 1.219-4.356a55.17 55.17 0 01-1.126-1.378 25.765 25.765 0 01-1.587-2.225l1.45-3.903.985-2.648a12.81 12.81 0 001.539 2.217c2.802 3.218 6.171 3.588 6.171 3.588z"
      ></path>
      <path
        fill="url(#paint18_linear_1782_13601)"
        d="M235.725 16.047s6.537-2.55 4.887-6.595c-1.65-4.045-1.917-7.102-6.16-5.646-4.242 1.456-4.42 3.33-4.198 4.814.221 1.485 3.722 7.978 5.471 7.427z"
      ></path>
      <path
        fill="url(#paint19_linear_1782_13601)"
        d="M269.488 54.248c-2.639 3.791-22.353 8.859-22.12 6.235a28.701 28.701 0 000-5.178 35.033 35.033 0 00-.21-1.79c-.507-3.449-1.69-7.884-4.344-13.02a49.102 49.102 0 00-.622-1.165 40.015 40.015 0 01-2.733-6.145c-1.366-3.949-1.917-7.726-1.228-10.197l.011-.034c4.121-1.907 5.718-9.3 5.718-9.3s14.06 2.525 21.936 21.274c-.002.002 5.885 16.017 3.592 19.32z"
      ></path>
      <path
        fill="url(#paint20_linear_1782_13601)"
        d="M225.2 51.082s-3.888.205-4.493.423c-.605.218-2.496 1.728-2.475 1.91.022.184.17.297.541.216.372-.082 1.557-.587 1.522-.416-.036.17-3.361 1.86-3.198 2.585.162.726 2.467 1.49 4.124 1.113 1.657-.377 4.322-3.183 4.589-3.286 2.274-.866-.61-2.545-.61-2.545z"
      ></path>
      <path
        fill="url(#paint21_linear_1782_13601)"
        d="M224.466 52.559c-.779-1.26-.218-1.834-.218-1.834s12.016-9.412 12.958-10.362c.941-.95 5.304-16.09 5.304-16.09s1.501-6.126 5.017-6.802c3.056-.587 5.168.924 6.55 2.705a6.517 6.517 0 01.441 7.33c-3.068 5.172-9.256 15.436-11.063 17.191-4.023 3.903-17.48 9.356-17.48 9.356a4.644 4.644 0 01-1.509-1.494z"
      ></path>
      <path
        fill="#000035"
        d="M241.543 1.929c.046-.09.18-.186.508-.286a1.367 1.367 0 00-.658-.024c-.184-.448-.63-1.21-1.467-1.217a1.024 1.024 0 01.657.605c-1.147-.727-3.272-.938-8.578.83-6.993 2.33-3.499 6.39-2.439 6.717 1.06.326 3.01-1.764 4.765-2.05 1.754-.285 2.704 2.16 3.38 2.368.677.208.29-.354.989-.91.7-.556 1.104.753 1.089 1.674-.015.922 1.352 1.793 1.352 1.793l.801-1.47c.8-1.47.584-6.806-.182-7.767l-.217-.263z"
      ></path>
      <path
        fill="#3A27AA"
        d="M130.837 188.482v8.003l-18.291-8.003h18.291z"
      ></path>
      <path
        fill="#573EC1"
        d="M291.153 120.592h-61.736v82.753h61.736v-82.753z"
      ></path>
      <path
        fill="#7656D8"
        d="M272.107 120.592h-60.423v82.753h60.423v-82.753z"
      ></path>
      <path
        fill="#3A27AA"
        d="M291.156 120.592v12.478l-19.047-12.478h19.047z"
      ></path>
      <path
        fill="#00C9AC"
        d="M241.931 161.832s-6.947 5.104-8.309 4.8c-1.361-.305 5.083-5.51 5.083-5.51-2.423-1.149-9.954-.429-9.954-.429s-2.508 7.983-3.752 7.354c-1.243-.63 2.586-6.957 2.586-6.957-2.698-.16-6.008 1.816-6.008 1.816-4.53 3.531.299 9.09.299 9.09s-.93 2.743-3.791 5.251c0 0-4.086 2.982-4.37 8.311l.741.066s1.175-4.963 3.312-7.311l.353-.355c.348-.318.592-.558.756-.735 1.063-1.008 2.533-2.292 3.24-2.417a1.378 1.378 0 01-.03-.351c.01.112.021.229.03.351.244 2.81 5.767 5.264 9.171 5.769 3.404.504 2.348-.875 1.177-2.918-1.172-2.044-4.876-2.958-5.721-3.9-.844-.942.902-.944 2.949-.021 2.046.923 2.327 3.383 5.612 4.937 3.285 1.553 8.225-3.826 8.225-3.826-2.617-.137-6.355-2.936-6.219-3.794.136-.859 6.345 1.481 7.406 1.592 1.062.111 3.474-1.191 3.85-5.41.377-4.219-6.636-5.403-6.636-5.403z"
      ></path>
      <path
        fill="url(#paint22_linear_1782_13601)"
        d="M205.249 165.099s7.665-4.345 4.088-10.23c0 0-2.861-3.616-5.926-4.545 0 0 1.634 8.778 0 8.986-1.635.207-1.125-9.917-1.125-9.917s-8.073-3.924-11.241-3.614c0 0 5.007 8.573 3.372 8.366-1.634-.206-7.256-8.883-7.256-8.883s-8.277-1.542-9.606 3.41c-1.328 4.951.818 7.435 2.044 7.745 1.226.31 9.096.206 8.891 1.24-.205 1.035-5.517 2.685-8.482 1.755 0 0 3.271 8.16 7.562 7.747 4.292-.414 5.622-3.099 8.278-3.305 2.657-.206 4.598.516 3.27 1.239-1.329.723-5.824.237-7.97 2.081-2.147 1.845-3.883 2.98.101 3.806 3.984.827 11.14.31 12.571-2.788l1.429-3.093z"
      ></path>
      <path
        fill="#00C9AC"
        d="M207.135 172.247c-2.149-3.965-2.047-7.423-2.047-7.423-2.456-7.727-23.953-14.644-23.953-14.644 27.332 10.983 22.521 17.695 22.521 17.695.736.426 1.84 2.461 2.607 4.023.108.267.277.634.538 1.133.105.226.188.414.245.54 1.407 3.498.661 9.538.661 9.538l.854.228c1.889-6.088-1.426-11.09-1.426-11.09z"
      ></path>
      <path
        fill="url(#paint23_linear_1782_13601)"
        d="M217.46 159.191s-10.041-5.657-5.355-13.335c0 0 3.75-4.714 7.766-5.927 0 0-2.142 11.449 0 11.718 2.141.269 1.473-12.93 1.473-12.93s10.577-5.119 14.728-4.714c0 0-6.561 11.179-4.418 10.91 2.143-.269 9.509-11.584 9.509-11.584s10.847-2.022 12.587 4.445c1.741 6.467-1.072 9.699-2.678 10.102-1.605.404-11.917.277-11.649 1.617.267 1.339 7.231 3.502 11.113 2.29 0 0-4.284 10.642-9.907 10.102-5.623-.539-7.365-4.04-10.847-4.31-3.481-.271-6.023.673-4.283 1.616 1.741.943 7.631.31 10.443 2.714 2.811 2.404 5.088 3.882-.138 4.965-5.226 1.083-14.594.403-16.468-3.637l-1.876-4.042z"
      ></path>
      <path
        fill="#00C9AC"
        d="M248.581 139.89s-28.074 9.061-31.286 19.189c0 0 .137 4.526-2.674 9.727 0 0-4.329 6.554-1.861 14.531l1.114-.299s-.973-7.914.864-12.498c.076-.169.184-.412.321-.707.334-.654.56-1.137.703-1.484 1-2.046 2.444-4.714 3.406-5.272 0 0-6.288-8.795 29.413-23.187z"
      ></path>
      <path
        fill="url(#paint24_linear_1782_13601)"
        d="M216.86 201.059c5.23 0 10.246-2.108 13.944-5.861 3.698-3.752 5.776-8.841 5.776-14.148h-39.439c0 5.307 2.077 10.396 5.775 14.148 3.699 3.753 8.714 5.861 13.944 5.861z"
      ></path>
      <path
        fill="url(#paint25_linear_1782_13601)"
        d="M221.716 200.906c-2.79.35-5.622.097-8.308-.74a19.794 19.794 0 01-7.272-4.12 20.006 20.006 0 01-4.953-6.77 20.174 20.174 0 01-1.759-8.226h4.926c0 4.877 1.765 9.585 4.962 13.241a19.78 19.78 0 0012.404 6.615z"
      ></path>
      <path
        fill="#8FEFDC"
        d="M234.717 200.487h-35.142a2.14 2.14 0 00-.932.217 2.451 2.451 0 00-.79.62c-.226.265-.405.58-.527.927a3.257 3.257 0 00-.185 1.094h40.01a3.257 3.257 0 00-.185-1.094 2.919 2.919 0 00-.527-.927 2.451 2.451 0 00-.79-.62 2.136 2.136 0 00-.932-.217z"
      ></path>
      <path
        fill="#59D1BA"
        d="M236.85 181.05h-39.976a.308.308 0 00-.214.084.278.278 0 00-.089.202c0 .076.032.149.089.202a.308.308 0 00.214.084h39.976c.081 0 .158-.03.214-.084a.276.276 0 00.089-.202.278.278 0 00-.089-.202.308.308 0 00-.214-.084z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_1782_13601"
          x1="185.773"
          x2="229.632"
          y1="52.215"
          y2="52.215"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECB9B8"></stop>
          <stop offset="1" stopColor="#E5908C"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_1782_13601"
          x1="176.652"
          x2="221.067"
          y1="93.82"
          y2="93.82"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7656D8"></stop>
          <stop offset="1" stopColor="#3A27AA"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_1782_13601"
          x1="165.569"
          x2="193.105"
          y1="104.947"
          y2="104.947"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7656D8"></stop>
          <stop offset="1" stopColor="#3A27AA"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_1782_13601"
          x1="142.061"
          x2="174.854"
          y1="68.15"
          y2="68.15"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECB9B8"></stop>
          <stop offset="1" stopColor="#E5908C"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_1782_13601"
          x1="172.545"
          x2="182.082"
          y1="44.701"
          y2="44.701"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECB9B8"></stop>
          <stop offset="1" stopColor="#E5908C"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear_1782_13601"
          x1="167.576"
          x2="177.669"
          y1="39.144"
          y2="39.144"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECB9B8"></stop>
          <stop offset="1" stopColor="#E5908C"></stop>
        </linearGradient>
        <linearGradient
          id="paint6_linear_1782_13601"
          x1="139.195"
          x2="147.979"
          y1="83.34"
          y2="83.34"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECB9B8"></stop>
          <stop offset="1" stopColor="#E5908C"></stop>
        </linearGradient>
        <linearGradient
          id="paint7_linear_1782_13601"
          x1="95.578"
          x2="104.761"
          y1="74.827"
          y2="74.827"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECB9B8"></stop>
          <stop offset="1" stopColor="#E5908C"></stop>
        </linearGradient>
        <linearGradient
          id="paint8_linear_1782_13601"
          x1="96.424"
          x2="107.347"
          y1="65.763"
          y2="65.763"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECB9B8"></stop>
          <stop offset="1" stopColor="#E5908C"></stop>
        </linearGradient>
        <linearGradient
          id="paint9_linear_1782_13601"
          x1="92.569"
          x2="109.878"
          y1="62.933"
          y2="62.933"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#003"></stop>
          <stop offset="1" stopColor="#3A27AA"></stop>
        </linearGradient>
        <linearGradient
          id="paint10_linear_1782_13601"
          x1="89.668"
          x2="107.784"
          y1="150.323"
          y2="150.323"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7656D8"></stop>
          <stop offset="1" stopColor="#3A27AA"></stop>
        </linearGradient>
        <linearGradient
          id="paint11_linear_1782_13601"
          x1="70.561"
          x2="104.823"
          y1="146.748"
          y2="146.748"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7656D8"></stop>
          <stop offset="1" stopColor="#3A27AA"></stop>
        </linearGradient>
        <linearGradient
          id="paint12_linear_1782_13601"
          x1="72.339"
          x2="78.064"
          y1="117.191"
          y2="117.191"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECB9B8"></stop>
          <stop offset="1" stopColor="#E5908C"></stop>
        </linearGradient>
        <linearGradient
          id="paint13_linear_1782_13601"
          x1="85.228"
          x2="110.265"
          y1="99.324"
          y2="99.324"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CAFFF5"></stop>
          <stop offset="1" stopColor="#8FEFDC"></stop>
        </linearGradient>
        <linearGradient
          id="paint14_linear_1782_13601"
          x1="97.748"
          x2="140.967"
          y1="87.438"
          y2="87.438"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CAFFF5"></stop>
          <stop offset="1" stopColor="#8FEFDC"></stop>
        </linearGradient>
        <linearGradient
          id="paint15_linear_1782_13601"
          x1="211.708"
          x2="219.43"
          y1="43.894"
          y2="43.894"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECB9B8"></stop>
          <stop offset="1" stopColor="#E5908C"></stop>
        </linearGradient>
        <linearGradient
          id="paint16_linear_1782_13601"
          x1="217.091"
          x2="255.712"
          y1="32.955"
          y2="32.955"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CAFFF5"></stop>
          <stop offset="1" stopColor="#8FEFDC"></stop>
        </linearGradient>
        <linearGradient
          id="paint17_linear_1782_13601"
          x1="236.87"
          x2="247.015"
          y1="16.181"
          y2="16.181"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECB9B8"></stop>
          <stop offset="1" stopColor="#E5908C"></stop>
        </linearGradient>
        <linearGradient
          id="paint18_linear_1782_13601"
          x1="230.186"
          x2="240.872"
          y1="9.756"
          y2="9.756"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECB9B8"></stop>
          <stop offset="1" stopColor="#E5908C"></stop>
        </linearGradient>
        <linearGradient
          id="paint19_linear_1782_13601"
          x1="237.932"
          x2="270.018"
          y1="37.431"
          y2="37.431"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CAFFF5"></stop>
          <stop offset="1" stopColor="#8FEFDC"></stop>
        </linearGradient>
        <linearGradient
          id="paint20_linear_1782_13601"
          x1="217.092"
          x2="226.684"
          y1="54.048"
          y2="54.048"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECB9B8"></stop>
          <stop offset="1" stopColor="#E5908C"></stop>
        </linearGradient>
        <linearGradient
          id="paint21_linear_1782_13601"
          x1="224.052"
          x2="255.44"
          y1="35.694"
          y2="35.694"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CAFFF5"></stop>
          <stop offset="1" stopColor="#8FEFDC"></stop>
        </linearGradient>
        <linearGradient
          id="paint22_linear_1782_13601"
          x1="177.137"
          x2="210.288"
          y1="158.189"
          y2="158.189"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CAFFF5"></stop>
          <stop offset="1" stopColor="#8FEFDC"></stop>
        </linearGradient>
        <linearGradient
          id="paint23_linear_1782_13601"
          x1="210.859"
          x2="254.295"
          y1="150.179"
          y2="150.179"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CAFFF5"></stop>
          <stop offset="1" stopColor="#8FEFDC"></stop>
        </linearGradient>
        <linearGradient
          id="paint24_linear_1782_13601"
          x1="197.141"
          x2="236.58"
          y1="191.055"
          y2="191.055"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CAFFF5"></stop>
          <stop offset="1" stopColor="#8FEFDC"></stop>
        </linearGradient>
        <linearGradient
          id="paint25_linear_1782_13601"
          x1="199.424"
          x2="221.716"
          y1="191.055"
          y2="191.055"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CAFFF5"></stop>
          <stop offset="1" stopColor="#8FEFDC"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default MeetTeamSmallSVG;