import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Home } from './pages/Home';
import { Navbar } from './components/navbar/Navbar';
import { Products } from './pages/Products';
import { Pricing } from './pages/Pricing';
import { Contact } from './pages/Contact';
import { About } from './pages/About';
import { MobileNavbar } from './components/navbar/MobileNavbar';
import { BookDemoModal } from './components/BookDemoModal';
import { Help } from './pages/Help';
import { Jobs } from './pages/Jobs';

function App() {
	const [isOpen, setIsOpen] = React.useState(false);
	const toggleOpen = () => setIsOpen(!isOpen);

	return (
		<div className="bg-gradient-to-r from-jig-purple/10 to-jig-purple/10 via-jig-blue/30 font-gordita">
			<BookDemoModal isOpen={isOpen} toggleOpen={toggleOpen} />
			<div className="sticky top-0 z-50 shadow-md hidden md:block">
				<Navbar toggleOpen={toggleOpen} />
			</div>
			<div className="sticky top-0 z-50 shadow-md md:hidden">
				<MobileNavbar />
			</div>
			<Routes>
				<Route path="/" element={<Home toggleOpen={toggleOpen} />} />
				<Route
					path="/products"
					element={<Products toggleOpen={toggleOpen} />}
				/>
				<Route path="/pricing" element={<Pricing toggleOpen={toggleOpen} />} />
				<Route path="/contact" element={<Contact toggleOpen={toggleOpen} />} />
				<Route path="/about" element={<About toggleOpen={toggleOpen} />} />
				<Route path="/help" element={<Help toggleOpen={toggleOpen} />} />
				<Route path="/jobs" element={<Jobs toggleOpen={toggleOpen} />} />
			</Routes>
		</div>
	);
}

export default App;
