import React from "react";
import { BlueButton } from "./BlueButton";
import MeetTeamSVG from "../../images/svg/MeetTeamSVG";

import { useTranslation } from "react-i18next";

export const MeetTheTeam = () => {
  const { t, i18n } = useTranslation();


  return(
    <div className="py-20 bg-jig-purple text-white relative px-5" >
      <div className="max-w-6xl m-auto px-5 md:px-0 text-center md:text-left">
        <div className="font-bold text-3xl">
          {t('meetTheTeam.title')}
        </div>
        <div className="text-lg max-w-lg pt-6">
          {t('meetTheTeam.description')}
        </div>
        <div className="font-bold pt-4 text-lg">
          {t('meetTheTeam.question')}
        </div>
        <div className="mt-4 w-fit md:w-full m-auto">
          <BlueButton text={t('meetTheTeam.button.text')} link="/jobs"/>
        </div>
        <div className="absolute right-0 bottom-0 hidden md:block">
          <MeetTeamSVG className="" />
        </div>
      </div>
    </div>
  )
}