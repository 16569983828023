import React from "react"; 
import { WordUnderline } from "../components/default/WordUnderline"; 
import { FooterComponent } from "../components/default/FooterComponent"; 
import { JiggrHelps } from "../components/default/JiggrHelps";
import { JiggrInterested } from "../components/default/JiggrInterested";
import { PageHeader } from "../components/default/PageHeader";
import { ProductsPageOverview } from "../components/default/ProductsPageOverview";

import { useTranslation } from "react-i18next";

export const Products = ({toggleOpen}: any) => {  
  const { t, i18n } = useTranslation();
  return(
      <div className="">

        <PageHeader text={t('productsPage.header')}/> 

        <ProductsPageOverview />

        <JiggrHelps toggleOpen={toggleOpen}/> 

        <JiggrInterested toggleOpen={toggleOpen}/> 

        <FooterComponent />  

      </div>
  )
}