import React from "react";
import { WordUnderline } from "./WordUnderline";
import { Motion } from "./Motion";
import SearchSVG from "../../images/svg/SearchSVG";
import RocketSVG from "../../images/svg/RocketSVG";
import PeopleSVG from "../../images/svg/PeopleSVG";
import DataSVG from "../../images/svg/DataSVG";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

export const OurProducts = () => {
  const { t, i18n } = useTranslation();
  const prods = t("ourProducts.products") as any;

  const navigate = useNavigate();

  // {console.log('prods', prods)}

  return (
    <div className="bg-white bg-background-logo bg-no-repeat bg-center overflow-hidden">
      <div className="max-w-6xl m-auto py-20">
        <div className="pb-8">
          <WordUnderline text={t("ourProducts.title")} />
        </div>
        <div className="flex flex-row md:flex-col overflow-auto m-auto md:w-fit gap-4 p-8">
          <div className="flex gap-4">
            <Motion duration={0.3} delay={0.0} direction="top" extraClass="">
              <div className="flex flex-col text-center bg-white/70 rounded-3xl p-4 shadow-xl w-[250px] h-[320px]">
                <div className="w-fit m-auto py-4">
                  <SearchSVG />
                </div>

                <div className="text-lg font-bold text-jig-purple">
                  {prods[0].name}
                </div>
                <div className="px-4 text-sm py-4 flex-1">
                  {prods[0].description}
                </div>
                <div
                  className="text-jig-purple underline decoration-white animate-200 cursor-pointer hover:decoration-jig-purple"
                  onClick={() => navigate("/products")}
                >
                  {prods[0].learnMore}
                </div>
              </div>
            </Motion>
            <Motion duration={0.3} delay={0.1} direction="top">
              <div className="flex flex-col text-center bg-white/70 rounded-3xl p-4 shadow-xl w-[250px] h-[320px]">
                <div className="w-fit m-auto py-4">
                  <RocketSVG />
                </div>
                <div className="text-lg font-bold text-jig-purple">
                  {prods[1].name}
                </div>
                <div className="px-4 text-sm py-4 flex-1">
                  {prods[1].description}
                </div>
                <div
                  className="text-jig-purple underline decoration-white animate-200 cursor-pointer hover:decoration-jig-purple "
                  onClick={() => navigate("/products")}
                >
                  {prods[1].learnMore}
                </div>
              </div>
            </Motion>
          </div>
          <div className="flex gap-4">
            <Motion duration={0.3} delay={0.2} direction="top">
              <div className="flex flex-col text-center bg-white/70 rounded-3xl p-4 shadow-xl w-[250px] h-[320px]">
                <div className="w-fit m-auto py-4">
                  <PeopleSVG />
                </div>
                <div className="text-lg font-bold text-jig-purple">
                  {prods[2].name}
                </div>
                <div className="px-4 text-sm py-4 flex-1">
                  {prods[2].description}
                </div>
                <div
                  className="text-jig-purple underline decoration-white animate-200 cursor-pointer hover:decoration-jig-purple "
                  onClick={() => navigate("/products")}
                >
                  {prods[2].learnMore}
                </div>
              </div>
            </Motion>
            <Motion duration={0.3} delay={0.3} direction="top">
              <div className="flex flex-col text-center bg-white/70 rounded-3xl p-4 shadow-xl w-[250px] h-[320px]">
                <div className="w-fit m-auto py-4">
                  <DataSVG />
                </div>
                <div className="text-lg font-bold text-jig-purple">
                  {prods[3].name}
                </div>
                <div className="px-4 text-sm py-4 flex-1">
                  {prods[3].description}
                </div>
                <div
                  className="text-jig-purple underline decoration-white animate-200 cursor-pointer hover:decoration-jig-purple "
                  onClick={() => navigate("/products")}
                >
                  {prods[3].learnMore}
                </div>
              </div>
            </Motion>
          </div>
        </div>
      </div>
    </div>
  );
};
