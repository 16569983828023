import * as React from "react"

function DataSVG(props: any) {
  return (
    <svg
      width={74}
      height={68}
      viewBox="0 0 74 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.803 61.402V6.596c.029-.036.072-.069.081-.111C1.88 2.478 4.362.55 8.511.55h56.962c.612-.019 1.224.016 1.83.104 3.547.631 5.89 3.471 5.892 7.132v52.487a6.853 6.853 0 01-.997 3.729c-1.496 2.388-3.691 3.455-6.513 3.453-19.129-.014-38.257-.014-57.384 0-.238 0-.476 0-.715-.016a6.995 6.995 0 01-2.662-.62 6.93 6.93 0 01-2.212-1.591c-1.046-1.082-1.56-2.412-1.909-3.826zm23.636 1.393H65.67c1.906 0 2.8-.903 2.8-2.793V23.944H24.439v38.851zM5.515 19.152h62.96v-.67V7.93c0-1.834-.922-2.727-2.797-2.727H8.289c-1.861 0-2.779.902-2.781 2.74v10.485l.007.724zm14.133 43.643V23.948H5.515v36.377c.045 1.527 1.001 2.465 2.55 2.477 1.507.012 3.015 0 4.529 0l7.054-.007z"
        fill="#A46BFF"
      />
      <path
        d="M46.391 31.658h14.777c1.752 0 2.917 1.372 2.512 2.918-.133.5-.429.943-.841 1.26a2.4 2.4 0 01-1.442.496c-.565.024-1.132 0-1.697 0H31.853c-1.387 0-2.314-.598-2.622-1.678-.45-1.563.668-2.986 2.398-2.998 2.638-.019 5.274 0 7.913 0l6.85.002zM46.392 54.996H31.615c-1.709 0-2.812-1.363-2.412-2.916a2.31 2.31 0 01.76-1.188 2.351 2.351 0 011.316-.533c.165-.011.331 0 .496 0H61.09c1.404 0 2.383.708 2.598 1.886a2.283 2.283 0 01-.498 1.933 2.356 2.356 0 01-1.835.827c-2.331.024-4.662 0-6.996 0l-7.967-.009zM46.394 45.664H31.768c-1.285 0-2.145-.545-2.505-1.544a2.33 2.33 0 01.25-2.039 2.37 2.37 0 01.763-.736c.307-.185.652-.3 1.01-.334a4.97 4.97 0 01.496-.014h29.315c1.63 0 2.679.987 2.622 2.446a2.263 2.263 0 01-.597 1.456 2.31 2.31 0 01-1.408.727 7.456 7.456 0 01-.917.038H46.394zM33.853 12.192a2.329 2.329 0 01-.703 1.615 2.38 2.38 0 01-1.642.675 2.38 2.38 0 01-1.653-.646 2.33 2.33 0 01-.733-1.602 2.328 2.328 0 01.703-1.615 2.381 2.381 0 013.296-.03c.445.42.708.995.732 1.603zM22.045 9.884c.469-.003.928.133 1.319.389.39.256.695.622.874 1.05a2.31 2.31 0 01-.52 2.553 2.385 2.385 0 01-2.586.488 2.354 2.354 0 01-1.052-.876 2.315 2.315 0 01-.38-1.308 2.285 2.285 0 01.692-1.629 2.335 2.335 0 011.653-.667zM10.26 12.227c.001-.46.14-.909.4-1.29a2.34 2.34 0 011.056-.852 2.37 2.37 0 012.557.516 2.296 2.296 0 01.492 2.534 2.326 2.326 0 01-.873 1.036 2.364 2.364 0 01-1.308.38 2.354 2.354 0 01-1.648-.687 2.301 2.301 0 01-.676-1.637z"
        fill="#A46BFF"
      />
    </svg>
  )
}

export default DataSVG
