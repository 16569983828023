import React from "react";  
import { WordUnderline } from "./WordUnderline";
import { Motion } from "./Motion";

import DynamicLogo from  "./../../images/connections/DynamicLogo.png";
import HubspotLogo from  "./../../images/connections/HubspotLogo.png";
import OtysLogo from  "./../../images/connections/OtysLogo.png";
import PipedriveLogo from  "./../../images/connections/PipedriveLogo.png";
import SalesforceLogo from  "././../../images/connections/SalesforceLogo.png"; 

import { useTranslation } from "react-i18next";

export const ApiConnections = () => {
  const { t, i18n } = useTranslation();

  return(
    <div>
      <div className="m-auto py-20 bg-white">
        <div className="max-w-sm m-auto">
          <WordUnderline text={t('apiConnections.header')} small={true}/>
        </div>
        <div className="flex flex-col md:flex-row w-fit m-auto gap-8 mt-8">
          <Motion duration={0.3} delay={0.0}>
            <div className="flex items-center">
              <img 
                className="w-[200px] "
                src={SalesforceLogo}
              />
            </div>
          </Motion>
          <Motion duration={0.3} delay={0.1}>
            <div className="flex items-center mt-5">
              <img 
                className="w-[200px] "
                src={PipedriveLogo}
              />
            </div>
          </Motion>
          <Motion duration={0.3} delay={0.2}>
            <div className="flex items-center mt-3">
              <img 
                className="w-[200px] "
                src={DynamicLogo}
              />
            </div>
          </Motion>
        </div>
        <div className="flex flex-col md:flex-row m-auto gap-8 mt-8 w-fit">
          <Motion duration={0.3} delay={0.3}>
            <div className="flex items-center">
              <img 
                className="w-[200px] "
                src={OtysLogo}
              />
            </div>
          </Motion>
          <Motion duration={0.3} delay={0.4}>
            <div className="flex items-center">
              <img 
                className="w-[200px] "
                src={HubspotLogo}
              />
            </div>
          </Motion>
        </div> 
      </div>
    </div>
  )
}
