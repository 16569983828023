import React, { useState } from "react";
import { WordUnderline } from "./WordUnderline";
import { BlueButton } from "./BlueButton";
import { Motion } from "./Motion";

import en from './../../locales/en.json'
import nl from './../../locales/nl.json'
import { useTranslation } from "react-i18next";

type ContactUsProps = {
    showText?: boolean;
    toggleOpen: () => void
}   

export const ContactUs = ({showText = false, toggleOpen}: ContactUsProps ) => {
    const { t, i18n } = useTranslation(); 
    
    const [contactDetails, setContactDetails] = useState({
        name: "",
        email: "",
        company: "",
        message: ""
    })

    const handleChange = (e: any) => {
        setContactDetails({
            ...contactDetails,
            [e.target.name]: e.target.value
        })
    }

    const handleSend = () => {
        setContactDetails({
            name: "",
            email: "",
            company: "",
            message: ""
        })
        console.log("send enmail")
    }

    return(
        <div className="max-w-4xl m-auto pb-20 overflow-hidden px-5">
            <div className="flex flex-col md:flex-row">
                <Motion duration={0.5} delay={0.0} direction={"left"} extraClass="md:w-1/4 text-center md:text-left">
                    {!showText ?
                        <div className="w-fit ml-auto mr-auto md:ml-0 md:mr-auto pb-6">
                            <WordUnderline text={t('contactUsComponent.headers.contactUsTitle')} small/>
                        </div>
                        :
                        <div className="font-bold text-xl text-jig-purple pb-8">
                            {t('contactUsComponent.headers.helloText')}
                        </div>
                    }
                    <div className="font-semibold pb-1">
                    {t('contactUsComponent.headers.moreInfoPrompt')}
                    </div>
                    <div className="pb-4 md:pb-0 px-4 md:px-0">
                    {t('contactUsComponent.headers.leaveInfoPrompt')}
                    </div>
                    {/* <div className="pt-8">
                        <BlueButton text="Book Demo" link="/"/>
                    </div> */}
                </Motion>
                <Motion duration={0.5} delay={0.1} direction={"right"} extraClass="w-fit ml-auto md:w-2/3 flex flex-col gap-y-4 placeholder:text-jig-purple px-5 md:px-0">
                    <div>
                        <input 
                            className="inputfield"
                            placeholder={t('contactUsComponent.formFields.nameField.placeholder')}
                            value={contactDetails.name}
                            name="name"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="flex flex-row gap-x-4">
                        <input 
                            className="inputfield"
                            placeholder={t('contactUsComponent.formFields.emailField.placeholder')}
                            value={contactDetails.email}
                            name="email"
                            onChange={handleChange}
                        />
                        <input 
                            className="inputfield"
                            placeholder={t('contactUsComponent.formFields.companyField.placeholder')}
                            value={contactDetails.company}
                            name="company"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="flex flex-row gap-x-4">
                        <textarea 
                            className="w-full rounded-xl px-4 py-1 text-sm shadow-md placeholder:text-jig-purple focus:ring-0 focus:outline-jig-purple"
                            placeholder={t('contactUsComponent.formFields.messageField.placeholder')}
                            rows={5}
                            value={contactDetails.message}
                            name="message"
                            onChange={handleChange}
                        /> 
                    </div>
                </Motion>
            </div>
            <div className="flex flex-row pt-6 md:px-0">
                <Motion duration={0.5} delay={0.1} direction={"left"} extraClass="w-1/3 hidden md:block">
                    <BlueButton text={t('contactUsComponent.buttons.demoButton')} link="/" click={toggleOpen} useClick/>
                </Motion>
                <Motion duration={0.5} delay={0.2} direction={"right"} extraClass="md:w-2/3 w-full px-5 md:px-0">
                    <button disabled={!contactDetails.message || !contactDetails.email || !contactDetails.name} onClick={()=>handleSend()} className="w-full text-center bg-white text-center rounded-full border border-jig-purple py-1 font-semibold text-jig-purple shadow hover:bg-white/80 focus:bg-white/60 animate-200 cursor-pointer select-none disabled:border-gray-400 disabled:bg-gray-300 disabled:text-gray-400">
                        {t('contactUsComponent.buttons.sendButton')}
                    </button>
                </Motion>
            </div>
        </div>
    )
}