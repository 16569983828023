import { MinusIcon, PlusIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";

export const JobItem = ({item}: any) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = () => setIsOpen(!isOpen);

    return(
        <div className={`${isOpen ? "max-h-[15000px]" : "max-h-[58px]"} animate-200 shadow-lg overflow-hidden rounded-[25px] bg-white`}>
            <div className="w-full rounded-full bg-white shadow-lg py-3 relative z-10 flex flex-row justify-between px-5 items-center select-none" onClick={toggleOpen}>
                <div className="flex flex-col">
                    <div className="font-bold text-jig-purple">
                        {item.title}
                    </div>
                    <div className="font-light leading-[10px]">
                        <span>{item.sub}</span> | <span>{item.type}</span>
                    </div>
                </div>
                <div>
                    {isOpen ?
                        <MinusIcon className="w-6 h-6 text-jig-purple" />
                        :
                        <PlusIcon className="w-6 h-6 text-jig-purple" />
                    }
                </div>
            </div>
            <div className={`px-5 bg-white -translate-y-[20px] transition-all duration-500 pt-[40px] overflow-hidden`}>
                <div className="" dangerouslySetInnerHTML={{__html: item.body}}/>
            </div>
        </div>
    )
}