import { PencilIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";  
import PurpleCheck from "../../images/svg/PurpleCheck";
import { PurpleButton } from "./PurpleButton";

import { useTranslation } from "react-i18next";

type ProductCardProps = {
  selected?: number,
  openDemo: any;
}

export const ProductCardProfessional = ({ selected, openDemo }: ProductCardProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);
  const { t, i18n } = useTranslation();

  return(  
    <div className="group [perspective:750px]"> 
      <div className={`relative h-[420px] w-64 aspect-video rounded-3xl shadow-lg shadow-black/40 transition-all duration-[500ms] [transform-style:preserve-3d] ${isOpen ? "[transform:rotateY(180deg)]" : "" }`}>
        
        {/* Card Front */}
        <div className="absolute inset-0 overflow-hidden rounded-3xl bg-white flex flex-col p-4 box-rotate">
          <div className="pb-2">
            <div className="font-bold text-jig-purple text-2xl">
              {t('productCardProfessional.title')}
            </div>
            {selected === 0 ?
            <>
              <div className="font-black text-4xl" dangerouslySetInnerHTML={{__html: t('productCardProfessional.monthlyPrice')}}>
                {/* {t('productCardProfessional.monthlyPrice} */}
              </div>
              <div className="font-normal">
                {t('productCardProfessional.annualDiscountMessage')}
              </div>
            </>
            :
            <>
              <div className="font-black text-4xl" dangerouslySetInnerHTML={{__html:t('productCardProfessional.annualPrice')}}>
                {/* {t('productCardProfessional.annualPrice} */}
              </div>
              <div className="font-normal">
                {t('productCardProfessional.annualSavingsMessage')}
              </div>
            </>
            }
          </div>
          <div className="flex-1 flex flex-col gap-y-2">
            <div className="flex items-center gap-x-3">
              <div className="">
                <PurpleCheck />
              </div>
              <div dangerouslySetInnerHTML={{__html: t('productCardProfessional.features.feature1')}}>
                {/* {t('productCardProfessional.features[0]} */}
              </div>
            </div>
            <div className="flex items-center gap-x-3">
              <div className="">
                <PurpleCheck />
              </div>
              <div dangerouslySetInnerHTML={{__html: t('productCardProfessional.features.feature2')}}>
                {/* {t('productCardProfessional.features[1]} */}
              </div>
            </div>
            <div className="flex items-center gap-x-3">
              <div className="">
                <PurpleCheck />
              </div>
              <div dangerouslySetInnerHTML={{__html: t('productCardProfessional.features.feature3')}}>
                {/* {t('productCardProfessional.features[2]} */}
              </div>
            </div>
            <div className="flex items-center gap-x-3">
              <div className="">
                <PurpleCheck />
              </div>
              <div dangerouslySetInnerHTML={{__html: t('productCardProfessional.features.feature4')}}>
                {/* {t('productCardProfessional.features[3]} */}
              </div>
            </div>
          </div>
          <div className="select-none font-semibold text-jig-purple text-center underline decoration-white cursor-pointer hover:decoration-jig-purple animate-200" onClick={toggleOpen}>
            {t('productCardProfessional.moreDetails')}
          </div>
          {/* <div className="m-auto w-fit pt-3">
            <PurpleButton text={t('productCardProfessional.bookDemoButton} link="/" click={openDemo} useClick/>
          </div> */}
        </div>

        {/* Card Back */}
        <div className="absolute inset-0 h-full w-full rounded-3xl bg-white [transform:rotateY(180deg)] box-rotate overflow-hidden p-4 flex flex-col" onClick={toggleOpen}>
          <div className="flex flex-col gap-y-2 flex-1">
            <div className="flex items-center gap-x-3">
              <div className="">
                <PurpleCheck />
              </div>
              <div dangerouslySetInnerHTML={{__html: t('productCardProfessional.features.feature5')}}>
              {/* {t('productCardProfessional.features[4]} */}
              </div>
            </div>
            <div className="flex items-center gap-x-3">
              <div className="">
                <PurpleCheck />
              </div>
              <div dangerouslySetInnerHTML={{__html: t('productCardProfessional.features.feature6')}}>
              {/* {t('productCardProfessional.features[5]} */}
              </div>
            </div>
            <div className="flex items-center gap-x-3">
              <div className="">
                <PurpleCheck />
              </div>
              <div dangerouslySetInnerHTML={{__html: t('productCardProfessional.features.feature7')}}>
              {/* {t('productCardProfessional.features[6]} */}
              </div>
            </div>
            <div className="flex items-center gap-x-3">
              <div className="">
                <PurpleCheck />
              </div>
              <div dangerouslySetInnerHTML={{__html: t('productCardProfessional.features.feature8')}}>
              {/* {t('productCardProfessional.features[7]} */}
              </div>
            </div>
            <div className="flex items-center gap-x-3">
              <div className="">
                <PurpleCheck />
              </div>
              <div dangerouslySetInnerHTML={{__html: t('productCardProfessional.features.feature9')}}>
              {/* {t('productCardProfessional.features[8]} */}
              </div>
            </div>
            <div className="flex items-center gap-x-3">
              <div className="">
                <PurpleCheck />
              </div>
              <div dangerouslySetInnerHTML={{__html: t('productCardProfessional.features.feature10')}}>
              {/* {t('productCardProfessional.features[9]} */}
              </div>
            </div>
          </div>

          <div className="select-none font-semibold text-jig-purple text-center underline decoration-white cursor-pointer hover:decoration-jig-purple animate-200" onClick={toggleOpen}>
            {t('productCardProfessional.fewerDetails')}
          </div>
          {/* <div className="m-auto w-fit pt-3">
            <PurpleButton text={messages.bookDemoButton} link="/" click={openDemo} useClick/>
          </div> */}
        </div>
        
      </div>
    </div> 
  )
}