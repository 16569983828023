import React from "react";
import { Motion } from "./Motion";
import { PurpleButton } from "./PurpleButton";

import BadgeIcon from  "./../../images/BadgeIcon.png";
import InfoIcon from  "./../../images/InfoIcon.png";
import LightbulbIcon from  "./../../images/LightbuldIcon.png";
import SearchIcon from  "./../../images/SearchIcon.png";

import { useTranslation } from "react-i18next";

export const JiggrHelps = ({toggleOpen}:any) => {
  const { t, i18n } = useTranslation();

  return(
    <div className="max-w-6xl m-auto lg:w-fit py-20 px-5">
      <div className="text-center text-h3 text-jig-purple ">
        {t('jiggrHelps.header')}
      </div>
      <div className="flex flex-row py-16 gap-x-4 overflow-auto px-8">
        <Motion duration={0.3} delay={0.0}>
          <div className="rounded-2xl shadow-xl bg-white w-[200px] h-[250px] flex flex-col items-center">
            <div className="flex-1 flex items-center">
              <img 
                className="w-[75px] h-[75px]"
                src={LightbulbIcon}
              />
            </div>
            <div className="flex-1 text-center font-body text-jig-purple">
              {t('jiggrHelps.discoverOpportunities')}
            </div>
          </div>
        </Motion>
        <Motion duration={0.3} delay={0.1}>
          <div className="rounded-2xl shadow-xl bg-white w-[200px] h-[250px] flex flex-col items-center">
            <div className="flex-1 flex items-center">
              <img 
                className="w-[75px] h-[75px]"
                src={SearchIcon}
              />
            </div>
            <div className="flex-1 text-center font-body text-jig-purple">
              {t('jiggrHelps.qualifiedLeads')}
            </div>
          </div>
        </Motion>
        <Motion duration={0.3} delay={0.2}>
          <div className="rounded-2xl shadow-xl bg-white w-[200px] h-[250px] flex flex-col items-center">              
            <div className="flex-1 flex items-center">
              <img 
                className="w-[75px] h-[75px]"
                src={InfoIcon}
              />
            </div>
            <div className="flex-1 text-center font-body text-jig-purple px-1">
             {t('jiggrHelps.notifications')}
            </div>
          </div>
        </Motion>
        <Motion duration={0.3} delay={0.3}>
          <div className="rounded-2xl shadow-xl bg-white w-[200px] h-[250px] flex flex-col items-center">
            <div className="flex-1 flex items-center">
              <img 
                className="w-[75px] h-[75px]"
                src={BadgeIcon}
              />
            </div>
            <div className="flex-1 text-center font-body text-jig-purple"> 
              {t('jiggrHelps.reliableInformation')}
            </div>
          </div>
        </Motion>
      </div>
      <div className="w-fit m-auto mt-16">
        <PurpleButton text={t('jiggrHelps.bookDemoButtonText')} link="/" click={toggleOpen} useClick/>
      </div>
    </div>
  )
}