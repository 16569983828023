import React, { useEffect, useRef } from 'react';
import { Motion } from './default/Motion';

import DemoImage from './../images/DemoImage.png';
import { InlineWidget } from 'react-calendly';
import { BlueButton } from './default/BlueButton';
import { AnimatePresence, motion } from 'framer-motion';

type BookDemoModalProps = {
	isOpen: boolean;
	toggleOpen: () => void;
};

export const BookDemoModal = ({ isOpen, toggleOpen }: BookDemoModalProps) => {
	const modalRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				modalRef.current &&
				!modalRef.current.contains(event.target as Node)
			) {
				toggleOpen();
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [toggleOpen]);

	return (
		<div>
			<AnimatePresence>
				{isOpen && (
					<motion.div
						key={0}
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.1, delay: 0.0 }}
						// variants={variants[direction]}
						className="p-12 fixed inset-0 z-[500] grid place-content-center backdrop-blur"
					>
						<motion.div
							key={0}
							initial={{ translateY: '-50%', opacity: 0 }}
							animate={{ translateY: '0%', opacity: 1 }}
							exit={{ translateY: '50%', opacity: 0 }}
							transition={{ duration: 0.2, ease: 'easeInOut', delay: 0.1 }}
							// variants={variants[direction]}
							className="p-12 fixed inset-0 z-[500] grid place-content-center backdrop-blur"
						>
							<div
								className="w-[95vw] md:max-h-[95vh] md:w-[60vw] md:px-12 bg-[#fdfbff] rounded-xl shadow-xl flex flex-col"
								ref={modalRef}
							>
								<img
									className="w-[170px] m-auto pt-8 hidden md:block"
									src={DemoImage}
								/>
								<div className="rounded-xl flex-1 overflow ">
									<InlineWidget url="https://calendly.com/d/ck6b-mjk-vsn/demo-jiggr" />
								</div>
								<div className="w-fit m-auto pt-2 pb-4">
									<div className="blue-button" onClick={() => toggleOpen()}>
										{'Close Window'}
									</div>
								</div>
							</div>
						</motion.div>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};
