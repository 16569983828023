import React, { useEffect, useState } from "react"; 
import { useLocation, useNavigate } from "react-router-dom";

import JiggrLogo from "./../../images/JiggrLogo.png"
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/solid";
import { PurpleButton } from "../default/PurpleButton"; 
import { useTranslation } from "react-i18next";

export const MobileNavbar = () => {
    const { t, i18n } = useTranslation();
    const links = t('links') as any; 

    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(true);
    const toggleOpen = () => setIsOpen(!isOpen);
    const { pathname } = useLocation(); 
    const [showLanguage, setShowLanguage] = useState(false);
    const toggleLanguage = () => setShowLanguage(!showLanguage);
    const changeLanguage = (lng: string) => {
      i18n.changeLanguage(lng);
    }
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return(
      <div className="absolute inset-0"> 
        <div className={`items-center m-auto bg-white/90 shadow-xl relative z-[50] ${isOpen ? "h-[76px] " : "h-screen " } animate-200 backdrop-blur flex flex-col overflow-hidden`}>
          <div className="flex min-h-[76px] items-center justify-between px-6 w-full">
            <div className="cursor-pointer" onClick={()=>navigate("/")}> 
              <img 
                src={JiggrLogo}
              />
            </div> 
            <div onClick={toggleOpen}>
            {isOpen ?
              <Bars3Icon className="w-6 h-6 text-gray-800" />
            :
              <XMarkIcon className="w-6 h-6 text-gray-800" />
            }
            </div>
          </div>
          <div className={`text-center flex-1 grid place-content-center gap-y-4 ${isOpen ? "opacity-0" : "opacity-100" } animate-300`}>
            <div className="text-2xl font-bold" onClick={()=>{navigate("/"); toggleOpen()}}>
              Home
            </div>
            {links.map((item: any, index: number) =>(
              <div key={index} className="text-2xl font-bold" onClick={()=>{navigate(item.path); toggleOpen()}}>
                {item.name}
              </div>
            ))}
            <div className="w-fit m-auto text-lg font-bold pt-8" onClick={()=>{window.location.replace('https://search.jiggr.cohttps://search.jiggr.co/account/signin'); toggleOpen()}}>
              Log In
            </div>
            <div className="w-fit m-auto pt-0" onClick={()=>{navigate(""); toggleOpen()}}>
              <PurpleButton text={t('buttonText')} link="" />
            </div>
            <div className="relative m-auto w-fit mt-8">
              <div className="cursor-pointer font-bold flex bg-jig-purple text-white rounded-full z-10 relative w-8 h-8 grid place-content-center shadow-lg" onClick={()=>toggleLanguage()}>
                {i18n.language == 'en' ? 'en' : 'nl'} 
              </div>
              <div className={`cursor-pointer absolute -bottom-7 shadow-lg w-8 grid place-content-center pb-1 bg-jig-purple/60 text-white font-bold rounded-b-full pt-4 ${showLanguage ? "" : "hidden"}`} onClick={()=>{changeLanguage(i18n.language == 'en' ? 'nl' : 'en'); toggleLanguage()}}>
                {i18n.language != 'en' ? 'en' : 'nl'}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}