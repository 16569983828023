import { useState, useEffect } from "react";
import JiggrIcon from "../../images/svg/JiggrIcon";
import { BlueButton } from "../default/BlueButton";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Navbar = ({ toggleOpen }: any) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { pathname, search } = useLocation();
  const links = t("links") as any;
  const [showLanguage, setShowLanguage] = useState(false);

  const toggleLanguage = () => setShowLanguage(!showLanguage);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    navigate(`${pathname}?lang=${lng}`);
  };

  useEffect(() => {
    const params = new URLSearchParams(search);
    const lang = params.get("lang");
    if (lang && lang !== i18n.language) {
      i18n.changeLanguage(lang);
    }
  }, [search, i18n]);

  return (
    <div className="items-center m-auto bg-white/90 relative z-[50]">
      <div className="m-auto py-4 max-w-6xl px-6 flex items-center justify-between">
        <div className="cursor-pointer" onClick={() => navigate("/")}>
          <JiggrIcon />
        </div>
        <div className="flex gap-x-4 text-base">
          {links.map((link: any, index: number) => {
            return (
              <div
                className={`cursor-pointer hover:text-black/80 active:text-black/70 animate-200 underline decoration-white hover:decoration-black animate-200 ${
                  pathname === link.path ? "font-bold" : ""
                }`}
                key={index}
                onClick={() => navigate(link.path)}
              >
                {link.name}
              </div>
            );
          })}
        </div>
        <div className="flex items-center gap-x-4">
          <div
            className="font-semibold cursor-pointer underline decoration-white hover:decoration-jig-purple/80 text-jig-purple hover:text-jig-purple/80 active:text-jig-purple/70 animate-200"
            onClick={() => {
              window.location.replace("https://search.jiggr.co/account/signin");
            }}
          >
            Log in
          </div>
          <div>
            <BlueButton
              text={t("buttonText")}
              link="/"
              click={toggleOpen}
              useClick
            />
          </div>
          <div className="relative m-auto w-fit ml-12">
            <div
              className="cursor-pointer font-bold flex bg-jig-purple text-white rounded-full z-10 relative w-8 h-8 items-center justify-center shadow-lg"
              onClick={() => toggleLanguage()}
            >
              {i18n.language === "en" ? "en" : "nl"}
            </div>
            <div
              className={`cursor-pointer absolute -bottom-7 shadow-lg w-8 flex items-center justify-center pb-1 bg-jig-purple/60 text-white font-bold rounded-b-full pt-4 ${
                showLanguage ? "" : "hidden"
              }`}
              onClick={() => {
                changeLanguage(i18n.language === "en" ? "nl" : "en");
                toggleLanguage();
              }}
            >
              {i18n.language !== "en" ? "en" : "nl"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
