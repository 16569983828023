import React from "react";

function JiggrIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="132"
      height="45"
      fill="none"
      viewBox="0 0 132 45"
    >
      <path
        fill="url(#paint0_linear_1746_710)"
        d="M28.563 30.234a10.59 10.59 0 01-3.445 2.349 10.404 10.404 0 01-4.062.82 10.417 10.417 0 01-4.083-.83 10.608 10.608 0 01-3.457-2.372l7.535-7.702H0a21.875 21.875 0 001.601 8.239 21.496 21.496 0 004.573 6.98 20.967 20.967 0 006.827 4.674 20.594 20.594 0 008.06 1.637c2.758.003 5.49-.55 8.04-1.627a20.967 20.967 0 006.817-4.65l-7.355-7.518z"
      ></path>
      <path
        fill="url(#paint1_linear_1746_710)"
        d="M42.107.971H21.03v10.664l10.538.109.111 10.747h.012v.056h10.415v-.03h.012L42.107.97z"
      ></path>
      <path
        fill="#003"
        d="M21.04 20.277a2.15 2.15 0 00-1.536.65 2.247 2.247 0 00-.636 1.572c0 .589.229 1.154.636 1.57.408.417.96.651 1.537.651a2.15 2.15 0 001.535-.651c.407-.417.636-.982.636-1.57 0-.59-.229-1.154-.636-1.57a2.15 2.15 0 00-1.535-.652zM52.674 13.933v3.86h5.275v8.172c0 1.944-.909 3.146-2.65 3.146-1.74 0-2.625-1.23-2.625-3.118h-4.549c0 4.597 2.717 7.526 7.174 7.526 4.525 0 7.202-2.848 7.202-7.526v-12.06h-9.827zM70.589 13.933h-4.58V33.09h4.58V13.933zM84.512 22.471l3.458 3.613c-.83 1.86-2.492 3.03-4.712 3.03-3.347 0-5.33-2.57-5.33-5.61 0-3.04 1.976-5.61 5.33-5.61 1.605 0 2.998.6 3.882 1.613l3.398-3.065c-1.82-1.86-4.364-2.954-7.28-2.954-5.811-.083-10.066 4.545-9.987 10.016-.08 5.473 4.176 10.098 9.987 10.017 1.298.035 2.59-.2 3.797-.69a9.587 9.587 0 003.226-2.163 9.835 9.835 0 002.132-3.286c.486-1.231.723-2.55.696-3.878 0-.3-.027-.629-.054-1.04l-8.543.007zM127.021 25.263c2.598-.757 4.285-2.6 4.285-5.446 0-3.887-3.131-5.884-7.122-5.884h-7.202V33.09h4.5v-8.703l5.352 8.703H132l-4.979-7.827zm-3.077-3.201h-2.469v-4.27h2.469c1.605 0 2.756.71 2.756 2.134s-1.151 2.136-2.756 2.136zM105.958 22.471l3.458 3.613c-.83 1.86-2.49 3.03-4.712 3.03-3.347 0-5.327-2.57-5.327-5.61 0-3.04 1.975-5.61 5.327-5.61 1.607 0 2.998.6 3.882 1.613l3.4-3.065c-1.82-1.86-4.363-2.954-7.282-2.954-5.809-.083-10.066 4.545-9.985 10.016-.081 5.473 4.176 10.098 9.985 10.017 1.298.035 2.59-.2 3.798-.69a9.6 9.6 0 003.226-2.163 9.848 9.848 0 002.133-3.286c.486-1.231.723-2.55.696-3.878 0-.3-.027-.629-.054-1.04l-8.545.007z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_1746_710"
          x1="0"
          x2="35.908"
          y1="33.259"
          y2="33.259"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#87F1F0"></stop>
          <stop offset="0.15" stopColor="#8AE5F1"></stop>
          <stop offset="0.41" stopColor="#91C5F5"></stop>
          <stop offset="0.77" stopColor="#9C91FB"></stop>
          <stop offset="1" stopColor="#A46BFF"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_1746_710"
          x1="21.038"
          x2="42.114"
          y1="11.767"
          y2="11.767"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#87F1F0"></stop>
          <stop offset="0.15" stopColor="#8AE5F1"></stop>
          <stop offset="0.41" stopColor="#91C5F5"></stop>
          <stop offset="0.77" stopColor="#9C91FB"></stop>
          <stop offset="1" stopColor="#A46BFF"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default JiggrIcon;
