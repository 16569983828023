import * as React from "react"

function SearchSVG(props: any) {
  return (
    <svg
      width={68}
      height={68}
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M61.089 68c-1.94-.314-3.329-1.487-4.66-2.838-3.768-3.845-7.561-7.667-11.38-11.464-1.704-1.7-2.427-3.651-1.828-6.035a.714.714 0 00-.157-.575c-1.379-1.41-2.776-2.8-4.194-4.22-6.079 4.503-12.803 6.132-20.192 4.465-6.22-1.404-11.13-4.876-14.665-10.137-6.433-9.574-5.195-22.97 4.175-31.354 8.505-7.612 22.214-7.802 31.025-.476 9.748 8.106 11.816 22.96 3.545 33.551.551.493 1.14.985 1.692 1.518.845.816 1.665 1.665 2.504 2.478a.856.856 0 00.656.238c2.323-.559 4.316.086 5.968 1.715 4.176 4.123 8.33 8.274 12.458 12.454 1.075 1.083 1.767 2.379 1.867 3.93a.88.88 0 00.097.237v.53c-.04.172-.097.339-.121.51a6.159 6.159 0 01-2.14 3.982c-.963.849-2.114 1.234-3.329 1.493L61.09 68zM43.055 23.937c.06-10.484-8.383-19.016-18.923-19.12-10.425-.105-19.115 8.534-19.17 19.059-.055 10.415 8.58 19.095 19.042 19.13 10.46.036 18.991-8.48 19.05-19.069zm19.919 37.61a5.228 5.228 0 00-.618-.87c-3.996-4.02-8.001-8.032-12.016-12.036-.764-.764-1.524-.87-2.069-.302-.544.568-.411 1.206.392 2.005 3.987 3.964 7.976 7.928 11.967 11.893.195.21.415.396.656.551.77.45 1.69-.114 1.688-1.241z"
        fill="#A46BFF"
      />
      <path
        d="M23.956 8.81c6.838.033 12.663 4.336 14.584 10.853.283 1.093.437 2.214.461 3.342.07 1.24-.922 2.236-2.211 2.39a2.394 2.394 0 01-2.568-1.84c-.192-.838-.259-1.713-.508-2.531a10.23 10.23 0 00-8.84-7.198 10.22 10.22 0 00-3.983.421 5.854 5.854 0 01-1.804.362 2.377 2.377 0 01-2.27-2.053A2.484 2.484 0 0118.5 9.79a15.427 15.427 0 015.456-.98z"
        fill="#A46BFF"
      />
    </svg>
  )
}

export default SearchSVG
