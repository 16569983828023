import { ChevronUpDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router";

import en from './../../locales/en.json'
import nl from './../../locales/nl.json'

import { link } from "fs";
import { useTranslation } from "react-i18next";

export const FooterComponent = () => {
  const { t, i18n } = useTranslation();
  const links = t('links') as any; 
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [showLanguage, setShowLanguage] = useState(false);
  const toggleLanguage = () => setShowLanguage(!showLanguage);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  }

  return(
    <div className="bg-jig-blue py-20 text-jig-purple overflow-hidden px-5">
      <div className="max-w-6xl m-auto flex flex-col md:flex-row">
        <div className="flex-1 px-5 md:px-0">
          <div className="font-bold">
            {t('footerComponent.meaningOfJiggr.title')}
          </div>
          <div className="pr-12 pt-4">
            {t('footerComponent.meaningOfJiggr.description')}
          </div>
          <div className="flex-row pt-12 gap-x-4 hidden md:flex">
            <div className="relative">
              <div className="cursor-pointer flex" onClick={()=>toggleLanguage()}>
                {i18n.language == 'en' ? 'English' : 'Nederlands'} <span><ChevronUpIcon className="w-4 h-4 ml-1"/></span>
              </div>
              <div className={`cursor-pointer absolute bottom-6 ${showLanguage ? "" : "hidden"}`} onClick={()=>{changeLanguage(i18n.language == 'en' ? 'nl' : 'en'); toggleLanguage()}}>
                {i18n.language != 'en' ? 'English' : 'Nederlands'}
              </div>
            </div> 
            <div className="cursor-pointer underline decoration-jig-blue hover:decoration-jig-purple animate-200 cursor-pointer">
              <a 
                href="https://jiggr-cdn.ams3.digitaloceanspaces.com/docs/terms-conditions-221020.pdf" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                {t('footerComponent.termsAndConditions.text')}
              </a>
            </div>
              <div className="cursor-pointer underline decoration-jig-blue hover:decoration-jig-purple animate-200 cursor-pointer">
                <a 
                  href="https://jiggr-cdn.ams3.digitaloceanspaces.com/docs/privacy-policy-221020.pdf" 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  {t('footerComponent.privacyPolicy.text')}
                </a>
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-row px-5 md:px-0 pt-4 md:pt-0">
          <div className="flex-1">
            <div className="font-bold">
              {t('footerComponent.menu.title')}
            </div>
            <div className="pt-4">
              <div className={`footer-link ${pathname == "/about" ? "font-bold" : ""}`} onClick={()=>navigate("/about")}>
                {links[0].name}
              </div>
              <div className={`footer-link ${pathname == "/products" ? "font-bold" : ""}`} onClick={()=>navigate("/products")}>
                {links[1].name}
              </div>
              <div className={`footer-link ${pathname == "/pricing" ? "font-bold" : ""}`} onClick={()=>navigate("/pricing")}>
                {links[2].name}
              </div>
              <div className={`footer-link ${pathname == "/contact" ? "font-bold" : ""}`} onClick={()=>navigate("/contact")}>
                {links[3].name}
              </div>
              <div className={`footer-link ${pathname == "/jobs" ? "font-bold" : ""}`} onClick={()=>navigate("/jobs")}>
                {t('footerComponent.menu.jobs')}
              </div>
              <div className={`footer-link ${pathname == "/help" ? "font-bold" : ""}`} onClick={()=>navigate("/help")}>
                {t('footerComponent.menu.help')}
              </div> 
            </div>
          </div>
          <div className="flex-1">
            <div className="font-bold">
              Contact
            </div>
            <div className="pt-4">
              <div className="cursor-pointer underline decoration-jig-blue hover:decoration-jig-purple animate-200">
                <a href="mailto:hello@jiggr.co">hello@jiggr.co</a>
              </div>
              <div className="cursor-pointer underline decoration-jig-blue hover:decoration-jig-purple animate-200">
                <a href="phone:0850606944">085 - 06 06 944</a>
              </div> 
            </div>
          </div>
        </div>
        <div className="flex flex-row pt-4 gap-x-4 px-5 md:hidden">
            {/* <div className="cursor-pointer underline decoration-jig-blue hover:decoration-jig-purple animate-200 flex flex-row">
              English <ChevronUpIcon className="w-4 h-4" />
            </div> */}
            <div className="cursor-pointer underline decoration-jig-blue hover:decoration-jig-purple animate-200">
              <a 
                href="https://jiggr-cdn.ams3.digitaloceanspaces.com/docs/terms-conditions-221020.pdf" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                {t('footerComponent.termsAndConditions.text')}
              </a>
            </div>
              <div className="cursor-pointer underline decoration-jig-blue hover:decoration-jig-purple animate-200">
                <a 
                  href="https://jiggr-cdn.ams3.digitaloceanspaces.com/docs/privacy-policy-221020.pdf" 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                {t('footerComponent.privacyPolicy.text')}
                </a>
            </div>
          </div>
      </div>
    </div>
  )
}