import { PencilIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";  
import PurpleCheck from "../../images/svg/PurpleCheck";
import { PurpleButton } from "./PurpleButton";
import BlueCheck from "../../images/svg/BlueCheck";
import { BlueButton } from "./BlueButton";

import { useTranslation } from "react-i18next";

type ProductCardProps = {
  selected: number,
  openDemo: any,
}

export const ProductCardBusiness = ({selected, openDemo}: ProductCardProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);
  const { t, i18n } = useTranslation();


  return(  
    <div className="group [perspective:750px]"> 
      <div className={`relative h-[420px] w-64 aspect-video rounded-3xl shadow-lg shadow-black/40 transition-all duration-[500ms] [transform-style:preserve-3d] ${isOpen ? "[transform:rotateY(180deg)]" : "" }`}>
        
        {/* Card Front */}
        <div className="absolute inset-0 overflow-hidden rounded-3xl bg-jig-purple text-white flex flex-col p-4 box-rotate">
          <div className="pb-2">
            <div className="font-bold text-jig-blue text-2xl">
              {t('productCardBusiness.title')}
            </div>
            {selected === 0 ?
            <>
              <div className="font-black text-4xl" dangerouslySetInnerHTML={{__html: t('productCardBusiness.monthlyPrice')}}>
                {/* €399<span className="text-lg font-semibold">/month</span> */}
              </div>
              <div className="font-normal">
                {t('productCardBusiness.annualDiscountMessage')}
              </div>
            </>
            :
            <>
              <div className="font-black text-4xl" dangerouslySetInnerHTML={{__html: t('productCardBusiness.annualPrice')}}>
                {/* €4.389<span className="text-lg font-semibold">/year</span> */}
              </div>
              <div className="font-normal">
                {t('productCardBusiness.annualSavingsMessage')}
              </div>
            </>
            }
          </div>
          <div className="flex-1 flex flex-col gap-y-2">
            <div className="flex items-center gap-x-3">
              <div className="">
                <BlueCheck />
              </div>
              <div dangerouslySetInnerHTML={{__html: t('productCardBusiness.features.feature1')}}>
              </div>
            </div>
            <div className="flex items-center gap-x-3">
              <div className="">
                <BlueCheck />
              </div>
              <div dangerouslySetInnerHTML={{__html: t('productCardBusiness.features.feature2')}}>
              </div>
            </div>
            <div className="flex items-center gap-x-3">
              <div className="">
                <BlueCheck />
              </div>
              <div dangerouslySetInnerHTML={{__html: t('productCardBusiness.features.feature3')}}>
              </div>
            </div>
            <div className="flex items-center gap-x-3">
              <div className="">
                <BlueCheck />
              </div>
              <div dangerouslySetInnerHTML={{__html: t('productCardBusiness.features.feature4')}}>
              </div>
            </div>
          </div>
          <div className="select-none font-semibold text-white text-center underline decoration-jig-purple cursor-pointer hover:decoration-white animate-200" onClick={toggleOpen}>
            {t('productCardBusiness.moreDetails')}
          </div>
          {/* <div className="m-auto w-fit pt-3">
            <BlueButton text={productCardBusiness.bookDemoButton} link="/" click={openDemo} useClick/>
          </div> */}
        </div>

        {/* Card Back */}
        <div className="absolute inset-0 h-full w-full rounded-3xl bg-jig-purple text-white [transform:rotateY(180deg)] box-rotate overflow-hidden p-4 flex flex-col" onClick={toggleOpen}>
        <div className="flex flex-col gap-y-2 flex-1">
            <div className="flex items-center gap-x-3">
              <div className="">
                <BlueCheck />
              </div>
              <div dangerouslySetInnerHTML={{__html: t('productCardBusiness.features.feature5')}}>

              </div>
            </div>
            <div className="flex items-center gap-x-3">
              <div className="">
                <BlueCheck />
              </div>
              <div dangerouslySetInnerHTML={{__html: t('productCardBusiness.features.feature6')}}>

              </div>
            </div>
            <div className="flex items-center gap-x-3">
              <div className="">
                <BlueCheck />
              </div>
              <div dangerouslySetInnerHTML={{__html: t('productCardBusiness.features.feature7')}}>

              </div>
            </div>
            <div className="flex items-center gap-x-3">
              <div className="">
                <BlueCheck />
              </div>
              <div dangerouslySetInnerHTML={{__html: t('productCardBusiness.features.feature8')}}>

              </div>
            </div>
            <div className="flex items-center gap-x-3">
              <div className="">
                <BlueCheck />
              </div>
              <div dangerouslySetInnerHTML={{__html: t('productCardBusiness.features.feature9')}}>

              </div>
            </div>
            <div className="flex items-center gap-x-3">
              <div className="">
                <BlueCheck />
              </div>
              <div dangerouslySetInnerHTML={{__html: t('productCardBusiness.features.feature10')}}>

              </div>
            </div>
          </div>

          <div className="select-none font-semibold text-white text-center underline decoration-jig-purple cursor-pointer hover:decoration-white animate-200" onClick={toggleOpen}>
            {t('productCardBusiness.fewerDetails')}
          </div>
          {/* <div className="m-auto w-fit pt-3">
            <BlueButton text={messages.bookDemoButton} link="/" click={openDemo} useClick/>
          </div> */}
        </div>
        
      </div>
    </div> 
  )
}