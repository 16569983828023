import React from "react"; 
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";

type MotionProps = {
  children: React.ReactNode,
  duration: number,
  delay: number,
  direction?: string,
  extraClass?: string
}

export const Motion = ({children, duration, delay, direction = "top", extraClass = ""}: MotionProps) => {

  const variants = {
    left: { 
        visible: { opacity: 1, translateX: 0},
        hidden: { opacity: 0, translateX: -100 } 
    },
    right: {
        visible: { opacity: 1, translateX: 0},
        hidden: { opacity: 0, translateX: 100 } 
    },
    top: {
        visible: { opacity: 1, translateY: 0},
        hidden: { opacity: 0, translateY: -100 } 
    },
    bottom: {
        visible: { opacity: 1, translateY: 0},
        hidden: { opacity: 0, translateY: 100 } 
    }
  } as any


  return(
    <motion.div  
      className={extraClass}
      initial="hidden"
      whileInView="visible"
      // viewport={{ once: true }}
      transition={{ duration: duration, ease: "easeInOut", delay: delay }}
      variants={variants[direction]}
    >
      {children}
    </motion.div>
  )
}