import React from 'react';

export const ProductIconCard = ({ item, icon }: any) => {
	return (
		<div className="bg-white ounded-xl w-full h-full aspect-video p-4 shadow-lg flex flex-col justify-center">
			<div className="flex gap-x-4 items-center pb-2">
				<div className="py-2">{icon}</div>
				<div className="font-bold text-jig-purple text-xl">{item.title}</div>
			</div>
			<div dangerouslySetInnerHTML={{ __html: item.body }} />
		</div>
	);
};
